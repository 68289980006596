import React from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton, Typography } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { copyTextToClipboard } from 'helpers/clipboard';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  close: {
    padding: theme.spacing(0.5),
  },
}));


const Media = ({ media, className }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  switch (media.mimeType) {
    case 'image/jpg':
    case 'image/jpeg':
    case 'image/png':
      return (
        <img
          alt={media.title}
          className={className}
          src={media.path}
        />);
    case 'video/mp4':
      if (media.path) {
        return (
          <>
            <div
              className={className}
              dangerouslySetInnerHTML={{ __html: media.path }}
            />
            <Button
              color="primary"
              onClick={async () => {
                try {
                  await copyTextToClipboard(media.path)
                  setOpen(true)
                } catch (error) {
                  console.log(error)
                }
              }}
              variant="contained"
            >Copier code vidéo</Button>
            <Snackbar
              action={[
                <IconButton
                  key="close"
                  aria-label="close"
                  color="inherit"
                  className={classes.close}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>,
              ]}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              autoHideDuration={6000}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={<span id="message-id">Vidéo copiée dans le presse papier</span>}
              onClose={handleClose}
              open={open}
            />
          </>
        )
      }
      return <Typography variant="body1">Vidéo en cours d'upload...</Typography>;
    default:
      return null;
  }
};

Media.propTypes = {
  className: PropTypes.string,
  media: PropTypes.any.isRequired
};

export default Media;
