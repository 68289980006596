import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  title: {
    display: 'flex',
    justifyContent: 'center'
  }
}));


const Download = props => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography variant="h1">Installer l'app</Typography>
        <Button
          color="primary"
          href="https://drive.google.com/file/d/1u8RiJKR0xeXX4GNqWcK9G3xi4CYGZgJG/view?usp=sharing"
          variant="contained"
        >
          Télécharger l'application
        </Button>
      </div>
    </div>
  )
}

export default Download;