import React, { useState } from 'react';
import {
  Card,
  Button,
  Divider,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CardActions,
  Typography,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CustomRouterLink from 'components/CustomRouterLink';

import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import { FactCard } from '..';
import PropTypes from 'prop-types';
import 'moment/locale/fr';

moment.locale('fr');

const useStyles = makeStyles(theme => ({
  root: { marginBottom: 10 },
  content: {
    padding: 0
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  subTitle: {
    marginTop: '30px',
    marginBottom: '20px'
  },
  tableTitle: {
    margin: '18px 18px 10px 18px'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  gameActions: {
    margin: '20px 0'
  }
}));

const GameContent = props => {
  const { teams, teamsError, facts, factsError, game, deleteGameSheet, deleteGame, deleteGameFact, updateGameFact, reportGame, addFact } = props;
  const classes = useStyles();

  const [homeExpanded, setHomeExpanded] = useState(false);
  const [visitorExpanded, setVisitorExpanded] = useState(false);
  console.log('game', game);
  const handleExpandedChange = (panel, type) => (event, isExpanded) => {
    if (type === 'home') setHomeExpanded(isExpanded ? panel : false);
    if (type === 'visitor') setVisitorExpanded(isExpanded ? panel : false);
  };

  const handleDeleteGameSheet  = () => {
    const ok = window.confirm('Etes-vous certain de vouloir supprimer cette gameSheet et toutes les infos liées au match?');
    if (ok) {
      deleteGameSheet();
    }
  }

  const handleDeleteGame  = () => {
    const ok = window.confirm('Etes-vous certain de vouloir supprimer ce match et toutes les infos liées?');
    if (ok) {
      deleteGame();
    }
  }

  const handleDeleteFact  = (id) => {
    const ok = window.confirm('Etes-vous certain de vouloir supprimer ce fait?');
    if (ok) {
      deleteGameFact(id);
    }
  }

  // const sorted = facts.sort((a, b) => {
  //   const aDate = moment(a.fact.date, 'DD-MM-YYYY HH:mm:ss');
  //   const bDate = moment(b.fact.date, 'DD-MM-YYYY HH:mm:ss');
  //   return bDate.diff(aDate);
  // });

  const sorted = facts;

  return (
    <>
      <div>
        <Typography
          className={classes.subTitle}
          variant="h3"
        >Le {moment(moment(game.date, 'DD-MM-yyyy').toDate()).format('DD-MM-yyy')} {moment(game.startHour, 'HH:mm:ss').format('HH:mm')}</Typography>
        <Typography
          className={classes.subTitle}
          variant="h3"
        >Score: {game.score}</Typography>
      </div>
      {teamsError !== '' ? (
        <Typography variant="body1">{teamsError}</Typography>
      ) : (
        <>
          <div className={classes.gameActions}>
            <Button onClick={handleDeleteGameSheet}>Supprimer la gameSheet</Button>
            <Button onClick={reportGame}>Reporter le match</Button>
            <Button
              color="primary"
              onClick={handleDeleteGame}
            >Supprimer le match</Button>
          </div>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              lg={6}
              sm={6}
              xl={6}
              xs={12}
            >
              <Card className={classes.root}>
                {/* <CardHeader
                  action={
                    <Button
                      color="primary"
                      size="small"
                      variant="outlined"
                    >
                      Modifier la feuille
                    </Button>
                  }
                  title="Joueurs Home"
                /> */}
                <Divider />
                <CardContent className={classes.content}>
                  <div className={classes.inner}>
                    <ExpansionPanel
                      expanded={homeExpanded === 'homeHolders'}
                      onChange={handleExpandedChange('homeHolders', 'home')}
                    >
                      <ExpansionPanelSummary
                        aria-controls="homeHoldersbh-content"
                        expandIcon={<ExpandMoreIcon />}
                        id="homeHoldersbh-header"
                      >
                        <Typography>Titulaires</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Numéro</TableCell>
                              <TableCell>Nom</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {teams.home.holders.map(p => (
                              <TableRow
                                hover
                                key={p.id}
                              >
                                <TableCell>{p.number}</TableCell>
                                <TableCell>
                                  {p.firstName + ' ' + p.lastName}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel
                      expanded={homeExpanded === 'homeSubstitutes'}
                      onChange={handleExpandedChange('homeSubstitutes', 'home')}
                    >
                      <ExpansionPanelSummary
                        aria-controls="homeSubstitutesbh-content"
                        expandIcon={<ExpandMoreIcon />}
                        id="homeSubstitutesbh-header"
                      >
                        <Typography>Remplaçants</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Numéro</TableCell>
                              <TableCell>Nom</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {teams.home.substitutes.map(p => (
                              <TableRow
                                hover
                                key={p.id}
                              >
                                <TableCell>{p.number}</TableCell>
                                <TableCell>
                                  {p.firstName + ' ' + p.lastName}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </div>
                </CardContent>
                <Divider />
                <CardActions className={classes.actions}>
                  <Button
                    color="primary"
                    component={CustomRouterLink}
                    size="small"
                    to={`/teams/${teams.home.teamId}`}
                    variant="text"
                  >
                    Vers l'équipe <ArrowRightIcon />
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid
              item
              lg={6}
              sm={6}
              xl={6}
              xs={12}
            >
              <Card className={classes.root}>
                {/* <CardHeader
                  action={
                    <Button
                      color="primary"
                      size="small"
                      variant="outlined"
                    >
                      Modifier la feuille
                    </Button>
                  }
                  title="Joueurs Visiteurs"
                /> */}
                <Divider />
                <CardContent className={classes.content}>
                  <div className={classes.inner}>
                    <ExpansionPanel
                      expanded={visitorExpanded === 'visitorHolders'}
                      onChange={handleExpandedChange('visitorHolders', 'visitor')}
                    >
                      <ExpansionPanelSummary
                        aria-controls="visitorHoldersbh-content"
                        expandIcon={<ExpandMoreIcon />}
                        id="visitorHoldersbh-header"
                      >
                        <Typography>Titulaires</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Numéro</TableCell>
                              <TableCell>Nom</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {teams.visitor.holders.map(p => (
                              <TableRow
                                hover
                                key={p.id}
                              >
                                <TableCell>{p.number}</TableCell>
                                <TableCell>
                                  {p.firstName + ' ' + p.lastName}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel
                      expanded={visitorExpanded === 'visitorSubstitutes'}
                      onChange={handleExpandedChange('visitorSubstitutes', 'visitor')}
                    >
                      <ExpansionPanelSummary
                        aria-controls="visitorSubstitutesbh-content"
                        expandIcon={<ExpandMoreIcon />}
                        id="visitorSubstitutesbh-header"
                      >
                        <Typography>Remplaçants</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Numéro</TableCell>
                              <TableCell>Nom</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {teams.visitor.substitutes.map(p => (
                              <TableRow
                                hover
                                key={p.id}
                              >
                                <TableCell>{p.number}</TableCell>
                                <TableCell>
                                  {p.firstName + ' ' + p.lastName}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </div>
                </CardContent>
                <Divider />
                <CardActions className={classes.actions}>
                  <Button
                    color="primary"
                    component={CustomRouterLink}
                    size="small"
                    to={`/teams/${teams.visitor.teamId}`}
                    variant="text"
                  >
                    Vers l'équipe <ArrowRightIcon />
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
      <Typography
        className={classes.subTitle}
        variant="h3"
      >
        Faits de match
      </Typography>
      <Button
        color="primary"
        onClick={addFact}
      >Ajouter un fait</Button>
      {factsError !== '' ? (
        <Typography variant="body1">{factsError}</Typography>
      ) : (
        sorted.map(f => (
          <FactCard
            deleteFact={handleDeleteFact}
            fact={f}
            key={f.fact.id}
            teams={teams}
            updateFact={updateGameFact}
          />
        ))
      )}
    </>
  );
};

GameContent.propTypes = {
  addFact: PropTypes.func.isRequired,
  deleteGame: PropTypes.func.isRequired,
  deleteGameFact: PropTypes.func.isRequired,
  deleteGameSheet: PropTypes.func.isRequired,
  facts: PropTypes.any.isRequired,
  factsError: PropTypes.string.isRequired,
  game: PropTypes.any.isRequired,
  reportGame: PropTypes.func.isRequired,
  teams: PropTypes.any,
  teamsError: PropTypes.string.isRequired,
  updateGameFact: PropTypes.func.isRequired,
}

export default GameContent;
