import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

const TeamNameWithAvatar = ({ team, classes, history }) => (
  <div
    className={classes.nameContainer}
    onClick={() => history.push('/teams/' + team.id)}
  >
    <Avatar
      className={classes.avatar}
      src={team.logo}
    />
    <Typography variant="body1">{team.name}</Typography>
  </div>
);

TeamNameWithAvatar.propTypes = {
  classes: PropTypes.any,
  team: PropTypes.any.isRequired
}

export default withRouter(TeamNameWithAvatar);
