import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const intentIcon = {
  SUCCESS: CheckCircleIcon,
  WARNING: WarningIcon,
  DANGER: ErrorIcon,
  INFO: InfoIcon
};

const styles = (theme) => ({
  SUCCESS: {
    backgroundColor: green[600]
  },
  DANGER: {
    backgroundColor: theme.palette.error.dark
  },
  INFO: {
    backgroundColor: theme.palette.primary.dark
  },
  WARNING: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: 15
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  snack: {
    marginTop: 20,
    boxShadow: 'none'
  }
});

const Message = (props) => {
  const { classes, className, message, intent } = props;
  const Icon = intentIcon[intent];
  return (
    <SnackbarContent
      className={classes[intent] + ' ' + className + ' ' + classes.snack}
      message={
        <span
          className={classes.message}
          id="client-snackbar"
        >
          <Icon className={classes.icon + ' ' + classes.iconVariant} />
          {message}
        </span>
      }
    />
  );
};

Message.defaultProps = {
  intent: 'INFO'
}
  
Message.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.any,
  intent: PropTypes.string,
  message: PropTypes.string.isRequired
};

export default withStyles(styles)(Message);
