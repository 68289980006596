import { authRequest } from '../helpers/request';
import endpoints from '../helpers/api';

export const addVideo = async (factID, video) => {
  try {
    const uriParts = video
      .split('/')
      .pop()
      .split('.');
    const fileType = uriParts[uriParts.length - 1];
    const fileName = uriParts[uriParts.length - 2];
    const formData = new FormData();
    formData.append('video', {
      uri: video,
      name: `${fileName}.${fileType}`,
      type: `video/${fileType}`,
    });
    const fetchOptions = {
      method: 'POST',
      body: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    };
    await authRequest(endpoints.addVideoFact(factID), fetchOptions);
  } catch (error) {
    console.log(error);
    throw new Error(error.message);
  }
};


export const addPicture = async (factID, picture) => {

};
