import { useState, useEffect } from 'react';

export const getGameScore = (teams, facts) => {
  let homeScore = 0;
  let visitorScore = 0;
  facts.map(f => {
    if (f.fact.factType.name === 'GOAL' && f.mainPlayer) {
      const player = f.mainPlayer.playerId;
      const team = getTeamFromPlayer(player, teams);
      if (team === 'home') {
        homeScore++;
      } else if (team === 'visitor') {
        visitorScore++;
      }
    }
  });

  return `${homeScore} - ${visitorScore}`;
};


export const getTeamFromPlayer = (playerId, teams) => {
  if (teams.home.holders.find(p => p.playerId === playerId)) {
    return 'home';
  } else if (teams.home.substitutes.find(p => p.playerId === playerId)) {
    return 'home';
  } else if (teams.visitor.holders.find(p => p.playerId === playerId)) {
    return 'visitor';
  } else if (teams.visitor.substitutes.find(p => p.playerId === playerId)) {
    return 'visitor';
  }
}

export const urlParam = name => {
  var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(
    window.location.href
  );
  if (results == null) {
    return null;
  } else {
    return decodeURI(results[1]) || null;
  }
};

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    },
    [value],
  );

  return debouncedValue;
}

export const factsWithPlayers = [
  'GOAL',
  'REPLACEMENT',
  'CORNER',
  'YELLOW_CARD',
  'RED_CARD',
  'FREEKICK',
  'OWN_GOAL',
  'PENALTY',
  'FOUL',
  'OPPORTUNITY',
]

export const undeletableFacts = [
  'KICKOFF',
  'HALFTIME',
  'STARTSECONDHALF',
  'EXTRATIME',
  'HALFTIMEXTRA',
  'STARTSECONDXTRA',
  'END_EXTRATIME',
  'GAMEOVER',
  'SUMMARY',
  'SHOOTOUT',
  'SHOOTOUT_IN',
  'SHOOTOUT_OUT',
]

export const typeByName = {
  COMMENT: 'Commentaire',
  REPLACEMENT: 'Remplacement',
  CORNER: 'Corner',
  YELLOW_CARD: 'Carton jaune',
  RED_CARD: 'Carton rouge',
  FREEKICK: 'Coup franc',
  GOAL: 'But',
  OWN_GOAL: 'But contre son camp',
  OPPORTUNITY: 'Occasion',
  PENALTY: 'Pénalty',
  TENSION: 'Tension',
  FOUL: 'Faute',
  KICKOFF: 'Début du match',
  PAUSE: 'Pause',
  END_PAUSE: 'Fin de pause',
  HALFTIME: 'Mi-temps',
  STARTSECONDHALF: 'Début deuxième période',
  EXTRATIME: 'Début des prolongation',
  HALFTIMEXTRA: 'Mi-temps des prolongations',
  STARTSECONDXTRA: 'Début deuxième période des prolongations',
  END_EXTRATIME: 'Fin des prolongations',
  GAMEOVER: 'Fin de match'
};

