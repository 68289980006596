import { withRouter } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Loader from 'components/Loader';
import {
  Typography, Avatar, Button, Tabs, Tab,
  DialogActions, TextField, DialogContentText,
  DialogContent, DialogTitle, Dialog
} from '@material-ui/core';
import useForm from 'react-hook-form';
import { makeStyles, useTheme } from '@material-ui/styles';
import { getTeam, getTeamPlayers, getTeamMatches, importTeam } from 'services/teams';
import RessourceTable from 'components/RessourceTable';
import CustomRouterLink from 'components/CustomRouterLink';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import moment from 'moment';
import TabPanel from 'components/TabPanel';
import TeamNameWithAvatar from 'components/TeamNameWithAvatar';
import RessourceToolbar from 'components/RessourceToolBar';
import Message from 'components/Message/Message';
import { addPerson, updatePerson, deletePerson } from 'services/persons';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  title: {
    display: 'flex',
    justifyContent: 'center'
  },
  againstIcon: {
    fontSize: '35px',
    marginLeft: '15px',
    marginRight: '15px'
  }
}));

const titleByType = {
  add: 'Ajouter',
  edit: 'Modifier',
  delete: 'Supprimer'
}

const Team = props => {
  const teamId = props.match.params.id;
  const classes = useStyles();
  const theme = useTheme();
  const { register, handleSubmit, errors, watch, setValue, reset } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      number: '',
      birthdate: ''
    }
  });

  const [team, setTeam] = useState(undefined);
  const [players, setPlayers] = useState(undefined);
  const [loaded, setLoaded] = useState(false);
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentId, setCurrentId] = useState(teamId);
  const [tab, setTab] = useState(0);
  const [importOpen, setImportOpen] = useState(false);
  const [playerDialogOpen, setPlayerDialogOpen] = useState(false);
  const [searchVal] = useState('');
  const [pageError, setPageError] = useState('');
  const [loadingMsg, setLoadingMsg] = useState('Chargement');
  const [importError, setImportError] = useState('');
  const [playerType, setPlayerType] = useState('add');
  const [playerError, setPlayerError] = useState('');
  const [formError, setFormError] = useState('');
  const [playerId, setPlayerId] = useState(undefined);
  const [filtered, setFiltered] = useState({ by: 'Numéro', type: 'asc'});

  const fetchTeam = async () => {
    setLoading(true);
    setTab(0);
    try {
      const teamData = await getTeam(teamId);
      setTeam(teamData);
      const playersData = await getTeamPlayers(teamId);
      setPlayers(
        playersData
          .sort((a,b) => a.number - b.number)
          .map(p => ({ ...p, id: p.playerId }))
      );
      const matches = await getTeamMatches(teamId);
      setGames(matches.map(g => ({ ...g, id: g.matchId })));
      setLoading(false);
    } catch (error) {
      setPageError(error.message);
      setLoading(false);
    }
    setCurrentId(teamId);
    setLoaded(true);
  };

  useEffect(() => {
    if (!loaded || currentId !== teamId) {
      fetchTeam();
    }
  }, [loaded, teamId, currentId]);

  const { firstName, lastName, number, birthdate } = watch();

  useEffect(() => {
    register({ name: 'firstName' });
    register({ name: 'lastName' });
    register({ name: 'number' });
    register({ name: 'birthdate' });
  }, [register]);

  if(pageError !== '') {
    return (<div className={classes.root}>
      <Typography variant="h1">{pageError}</Typography>
    </div>)
  }

  const handleChange = (name, e) => {
    e.persist();
    setValue(name, e.target.value);
  };

  const fields = [
    {
      name: 'Prénom',
      display: p => p.firstName
    },
    {
      name: 'Nom',
      display: p => p.lastName
    },
    {
      name: 'Numéro',
      display: p => p.number
    },
    {
      name: 'Date de naissance',
      display: p =>
        moment(p.birthdate, 'DD-MM-YYYY HH:mm:ss').format('DD/MM/YYYY')
    },
    {
      name: 'Action',
      display: p => (
        <>
          <Button
            color="primary"
            onClick={()=> {
              reset();
              setPlayerId(p.id);
              setValue('firstName', p.firstName);
              setValue('lastName', p.lastName);
              setValue('birthdate', moment(p.birthdate, 'DD-MM-YYYY HH:mm:ss').format('DD/MM/YYYY'));
              setValue('number', p.number);
              setPlayerType('edit');
              setPlayerDialogOpen(true);
            }}
            size="small"
            variant="text"
          >
            Modifier
          </Button>
          <Button
            color="primary"
            onClick={()=> {
              const ok = window.confirm(`Etes-vous certain de vouloir supprimer ce joueur: ${p.firstName} ${p.lastName}?`);
              if (ok) {
                deletePersonById(p.id);
              }
            }}
            size="small"
            variant="contained"
          >
            Supprimer
          </Button>
        </>
      )
    }
  ];

  const matchFields = [
    {
      name: 'Home',
      display: (g, c) => <TeamNameWithAvatar
        classes={c}
        team={{
          id: g.homeTeamId,
          name: g.homeTeamName,
          logo: null,
        }}
      />
    },
    {
      name: 'Visiteurs',
      display: (g, c) => <TeamNameWithAvatar
        classes={c}
        team={{
          id: g.visitorTeamId,
          name: g.visitorTeamName,
          logo: null,
        }}
      />
    },
    {
      name: 'Date du match',
      display: g => `${moment(g.date, 'DD-MM-YYYY HH:mm:ss').format('DD/MM/YYYY')} ${g.startHour}`
    },
    {
      name: 'Action',
      display: g => (
        <Button
          color="primary"
          component={CustomRouterLink}
          size="small"
          to={`/games/${g.id}`}
          variant="text"
        >
          Voir le match <ArrowRightIcon />
        </Button>
      )
    }
  ];

  const filter = (by) => {
    setPlayers(players.sort((a,b) => {
      switch (by) {
        case 'Prénom':
          if (filtered.by === 'Prénom' && filtered.type ==='asc' ) {
            setFiltered({ by: 'Prénom', type: 'desc'})
            return b.firstName.localeCompare(a.firstName);
          }
          setFiltered({ by: 'Prénom', type: 'asc'})
          return a.firstName.localeCompare(b.firstName);
        case 'Nom':
          if (filtered.by === 'Nom' && filtered.type ==='asc' ) {
            setFiltered({ by: 'Nom', type: 'desc'})
            return b.lastName.localeCompare(a.lastName);
          }
          setFiltered({ by: 'Nom', type: 'asc'})
          return a.lastName.localeCompare(b.lastName);
        case 'Numéro':
          if (filtered.by === 'Numéro' && filtered.type ==='asc' ) {
            setFiltered({ by: 'Numéro', type: 'desc'})
            return b.number - a.number;
          }
          setFiltered({ by: 'Numéro', type: 'asc'})
          return a.number - b.number;
        default:
          break;
      }
    }));
  }

  const teamTitle =
    team !== undefined ? (
      <div className={classes.title}>
        <Avatar
          className={classes.avatar}
          src={team.logo}
        />
        <Typography variant="h1">{team.name}</Typography>
      </div>
    ) : (
      ''
    );

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  }

  const openImportTeam = () => setImportOpen(!importOpen);
  const openPlayerDialog = () => setPlayerDialogOpen(!playerDialogOpen);

  const handleImport = async () => {
    setLoadingMsg('Upload de l\'équipe');
    try {
      const fileData = document.getElementById('importInput').files[0];
      setLoading(true);
      setImportOpen(false);
      await importTeam(fileData, teamId);
      const playersData = await getTeamPlayers(teamId);
      setPlayers(
        playersData
          .sort((a,b) => a.number - b.number)
          .map(p => ({ ...p, id: p.playerId }))
      );
      setLoading(false);
    } catch (error) {
      setImportError(error.message);
      setLoading(false);
      setImportOpen(true);
    }
  }

  const handlePlayer = async data => {
    setLoadingMsg('Envoi des données');
    try {
      setLoading(true);
      setImportOpen(false);
      switch (playerType) {
        case 'add':
          await addPerson({ ...data, teamId}, 'player');
          break;
        case 'edit':
          if (playerId) {
            await updatePerson({ id: playerId, ...data, teamId}, 'player');
          }
          break;
        default:
          setPlayerError('Action inconnue');
          setLoading(false);
          setPlayerDialogOpen(true);
          return;
      }
      const playersData = await getTeamPlayers(teamId);
      setPlayers(
        playersData
          .sort((a,b) => a.number - b.number)
          .map(p => ({ ...p, id: p.playerId }))
      );
      setPlayerDialogOpen(false);
      setLoading(false);
    } catch (error) {
      setPlayerError(error.message);
      setLoading(false);
      setPlayerDialogOpen(true);
    }
  }

  const deletePersonById = async (playerId) => {
    setFormError('');
    setLoading(true);
    if (playerId) {
      try {
        await deletePerson(playerId , 'player');
        const playersData = await getTeamPlayers(teamId);
        setPlayers(
          playersData
            .sort((a,b) => a.number - b.number)
            .map(p => ({ ...p, id: p.playerId }))
        );
        setLoading(false);
      } catch (error) {
        setFormError(error.message);
        setLoading(false);
      }
    }
  }

  return (
    <div className={classes.root}>
      {teamTitle}
      {/* <UsersToolbar /> */}
      <div className={classes.content}>
        {loading ? (
          <Loader message={loadingMsg} />
        ) : (
          <>
            <Dialog
              aria-labelledby="form-dialog-title"
              onClose={openImportTeam}
              open={importOpen}
            >
              <form onSubmit={handleSubmit(handleImport)}>
                <DialogTitle id="form-dialog-title">Importer une équipe</DialogTitle>
                <DialogContent>
                  {importError !== '' && <Message
                    intent="DANGER"
                    message={importError}
                  />}
                  <DialogContentText>
                      Veuillez bien respecter le format du fichier csv de base que vous pouver télécharger ici: <a href="/team.csv">Fichier d'exemple</a>
                  </DialogContentText>
                  <TextField
                    accept=".csv"
                    autoFocus
                    error={errors.file ? true : false}
                    fullWidth
                    helperText={
                      errors.file  && errors.file.message
                    }
                    id="importInput"
                    inputRef={register({
                      required: 'Ce champ est requis',
                      pattern: {
                        value: /.*.csv$/,
                        message: 'Fichier de type .csv requis'
                      }
                    })}
                    label="Fichier csv"
                    margin="dense"
                    name="csv"
                    type="file"
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    color="primary"
                    onClick={openImportTeam}
                  >
                      Annuler
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                  >
                      Importer
                  </Button>
                </DialogActions>
              </form>
            </Dialog>
            <Dialog
              aria-labelledby="form-dialog-title"
              onClose={openPlayerDialog}
              open={playerDialogOpen}
            >
              <form onSubmit={handleSubmit(handlePlayer)}>
                <DialogTitle id="form-dialog-title">{titleByType[playerType]} un joueur</DialogTitle>
                <DialogContent>
                  {playerError !== '' && <Message
                    intent="DANGER"
                    message={playerError}
                  />}
                  <TextField
                    autoFocus
                    error={errors.firstName ? true : false}
                    fullWidth
                    helperText={
                      errors.firstName  && errors.firstName.message
                    }
                    id="firstName"
                    inputRef={register({
                      required: 'Prénom requis'
                    })}
                    label="Prénom"
                    margin="dense"
                    name="firstName"
                    onChange={e => handleChange('firstName', e)}
                    type="text"
                    value={firstName}
                  />
                  <TextField
                    error={errors.lastName ? true : false}
                    fullWidth
                    helperText={
                      errors.lastName  && errors.lastName.message
                    }
                    id="lastName"
                    inputRef={register({
                      required: 'Nom requis'
                    })}
                    label="Nom"
                    margin="dense"
                    name="lastName"
                    onChange={e => handleChange('lastName', e)}
                    type="text"
                    value={lastName}
                  />
                  <TextField
                    error={errors.number ? true : false}
                    fullWidth
                    helperText={
                      errors.number  && errors.number.message
                    }
                    id="number"
                    inputRef={register({
                      required: 'Numéro requis',
                      pattern: {
                        value: /^\d{1,2}$/, 
                        message: 'Format incorrect'
                      }
                    })}
                    label="Numéro"
                    margin="dense"
                    name="number"
                    onChange={e => handleChange('number', e)}
                    type="text"
                    value={number}
                  />
                  <TextField
                    error={errors.birthdate ? true : false}
                    fullWidth
                    helperText={
                      errors.birthdate  && errors.birthdate.message
                    }
                    id="birthdate"
                    inputRef={register({
                      required: 'Date de naissance requise',
                      pattern: {
                        value: /\d{1,2}\/\d{1,2}\/\d{4}/, 
                        message: 'Format incorrect'
                      }
                    })}
                    label="Date de naissance (JJ/MM/AAAA)"
                    margin="dense"
                    name="birthdate"
                    onChange={e => handleChange('birthdate', e)}
                    type="text"
                    value={birthdate}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    color="primary"
                    onClick={openPlayerDialog}
                  >
                      Annuler
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                  >
                    {titleByType[playerType]}
                  </Button>
                </DialogActions>
              </form>
            </Dialog>
            {formError !== '' && <Message
              intent="DANGER"
              message={formError}
            />}
            <RessourceToolbar
              addTitle="Ajouter un joueur"
              onAdd={() => {
                setPlayerType('add');
                openPlayerDialog();
              }}
              onImport={openImportTeam}
              searchTitle="Rechercher un joueur"
              searchVal={searchVal}
            />
            <Tabs
              aria-label="full width tabs example"
              indicatorColor="primary"
              onChange={handleTabChange}
              textColor="primary"
              value={tab}
              variant="fullWidth"
            >
              <Tab
                label="Joueurs"
              />
              <Tab
                label="Matches"
              />
            </Tabs>
            <div>
              <TabPanel
                dir={theme.direction}
                index={0}
                value={tab}
              >
                <RessourceTable
                  fields={fields}
                  filter={filter}
                  filtered={filtered}
                  ressources={players}
                />
              </TabPanel>
              <TabPanel
                dir={theme.direction}
                index={1}
                value={tab}
              >
                <RessourceTable
                  fields={matchFields}
                  ressources={games}
                />
              </TabPanel>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default withRouter(Team);
