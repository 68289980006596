import { withRouter } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Loader from 'components/Loader';
import { Typography, FormControl, InputLabel, Select, Button, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { getChampionships, getDivisionsByChampionship, addGame } from 'services/games';
import { filterTeam } from 'services/teams';
import useForm from 'react-hook-form';
import Message from 'components/Message/Message';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import {  DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'moment/locale/fr';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  formWrap: {
    maxWidth: '400px',
    margin: '0 auto'
  },
  firstItem: {
    marginTop: '20px'
  },
  button: {
    margin: '20px 0'
  }
}));

const NewGame = props => {
  const { history } = props;
  const classes = useStyles();

  const [formError, setFormError] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [champ, setChamp] = useState('FOOTBALL PROVINCIAL PREMIERES');
  const [champs, setChamps] = useState([]);
  const [division, setDivision] = useState('Namur 1');
  const [divisionsLoading, setDivisionsLoading] = useState(false);
  const [divisions, setDivisions] = useState([]);
  const [teamsLoading, setTeamsLoading] = useState(false);
  const [teams, setTeams] = useState([]);
  const [homeTeam, setHomeTeam] = useState(null);
  const [visitorTeam, setVisitorTeam] = useState(null);
  const [date, setDate] = useState(new Date());
  const { handleSubmit } = useForm();

  const fetchTeams = async (search) => {
    try {
      setTeamsLoading(true);
      const data = await filterTeam('', search, 0, 200);
      setTeams(data.item2);
      setTeamsLoading(false);
    } catch (error) {
      setTeams([]);
      setTeamsLoading(false);
    }
  }

  const fetchDivisionsByChamp = async (ch) => {
    try {
      const dvs = await getDivisionsByChampionship(ch);
      setDivisions(dvs);
      setDivisionsLoading(false);
      return dvs;
    } catch (error) {
      setDivisions([]);
      setDivisionsLoading(false);
    }
  }

  const init = async () => {
    try {
      const champs = await getChampionships();
      setChamps(champs);
      const selectedChampId = champs.find(c => c.name === champ).id;
      const divs = await fetchDivisionsByChamp(selectedChampId);
      if (divs.length) {
        setDivision(divs[0].name);
        await fetchTeams(divs[0].name);
      }
      setLoaded(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setLoaded(true);
    }
  };

  const handleMatch = async () => {
    setFormError('');
    if (homeTeam === null) {
      setFormError('choisissez une home team');
      return;
    }
    if (visitorTeam === null) {
      setFormError('choisissez une visitor team');
      return;
    }
    setLoading(true);
    console.log('date', date);
    const body = {
      date: moment.utc(date).format('YYYY-MM-DD'),
      startHour: moment(date).format('HH:mm'),
      postalCode: 0,
      divisionName: division,
      homeTeamName: homeTeam.name,
      homeTeamInternalSisId: homeTeam.sisId,
      visitorTeamName: visitorTeam.name,
      visitorTeamInternalSisId: visitorTeam.sisId
    }
    console.log('body', body);
    try {
      const newGame = await addGame(body);
      setLoading(false);
      history.push('/games/' + newGame);
    } catch (error) {
      setFormError(error.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!divisionsLoading && loaded) {
      const selectedChampId = champs.find(c => c.name === champ).id;
      fetchDivisionsByChamp(selectedChampId);
    }
  }, [champ]);

  useEffect(() => {
    if (!divisionsLoading && loaded) {
      const selectedDivId = divisions.find(c => c.name === division).name;
      fetchTeams(selectedDivId);
    }
  }, [division]);

  useEffect(() => {
    if (!loaded) {
      init();
    }
  }, [loaded]);

  return (
    <div className={classes.root}>
      <Typography variant="h1">Ajouter un match</Typography>
      {/* <UsersToolbar /> */}
      <div className={classes.content}>
        {loading ? (
          <Loader />
        ) : (
          <div className={classes.formWrap}>
            <form onSubmit={handleSubmit(handleMatch)}>
              {formError !== '' && <Message
                intent="DANGER"
                message={formError}
              />}
              <FormControl
                className={classes.firstItem}
                // error={roleError !== '' ? true : false}
                fullWidth
                name="role"
              >
                <InputLabel
                  htmlFor="age-simple"
                >Championnat</InputLabel>
                <Select
                  fullWidth
                  onChange={(e) => setChamp(e.target.value)}
                  value={champ}
                >
                  {champs.map(c => (<MenuItem
                    key={c.name}
                    selected={c.name === champ}
                    value={c.name}
                  >
                    {c.name}
                  </MenuItem>))}
                </Select>
                {/* {roleError !== '' && <FormHelperText>{roleError}</FormHelperText>} */}
              </FormControl>
              <FormControl
                // error={roleError !== '' ? true : false}
                fullWidth
                name="role"
              >
                <InputLabel
                  htmlFor="age-simple"
                >Division</InputLabel>
                {divisionsLoading ? (<span>Chargement...</span>) : (
                  <Select
                    fullWidth
                    onChange={(e) => {
                      setDivision(e.target.value);
                    }}
                    value={division}
                  >
                    {divisions.map(c => (<MenuItem
                      key={c.name}
                      selected={c.name === champ}
                      value={c.name}
                    >
                      {c.name}
                    </MenuItem>))}
                  </Select>
                  /* {roleError !== '' && <FormHelperText>{roleError}</FormHelperText>} */
                )}
              </FormControl>
              <FormControl
                // error={roleError !== '' ? true : false}
                fullWidth
                name="role"
              >
                <InputLabel
                  htmlFor="age-simple"
                >Home</InputLabel>
                {teamsLoading ? (<span>Chargement...</span>) : (
                  <Select
                    fullWidth
                    onChange={(e) => setHomeTeam(teams.find(t => t.id === e.target.value))}
                    value={homeTeam !== null ? homeTeam.id : ''}
                  >
                    {teams.filter(t => visitorTeam === null ? true :  t.id !== visitorTeam.id).map(t => (<MenuItem
                      key={t.id + 'home'}
                      selected={homeTeam !== null && t.id === homeTeam.id}
                      value={t.id}
                    >
                      {t.name}
                    </MenuItem>))}
                  </Select>
                  /* {roleError !== '' && <FormHelperText>{roleError}</FormHelperText>} */
                )}
              </FormControl>
              <FormControl
                // error={roleError !== '' ? true : false}
                fullWidth
                name="role"
              >
                <InputLabel
                  htmlFor="age-simple"
                >Visiteur</InputLabel>
                {teamsLoading ? (<span>Chargement...</span>) : (
                  <Select
                    fullWidth
                    onChange={(e) => setVisitorTeam(teams.find(t => t.id === e.target.value))}
                    value={visitorTeam !== null ? visitorTeam.id : ''}
                  >
                    {teams.filter(t => homeTeam === null ? true :  t.id !== homeTeam.id).map(t => (<MenuItem
                      key={t.id + 'visitor'}
                      selected={visitorTeam !== null && t.id === visitorTeam.id}
                      value={t.id}
                    >
                      {t.name}
                    </MenuItem>))}
                  </Select>
                  /* {roleError !== '' && <FormHelperText>{roleError}</FormHelperText>} */
                )}
              </FormControl>
              <MuiPickersUtilsProvider
                libInstance={moment}
                locale="fr"
                utils={MomentUtils}
              >
                <FormControl
                  fullWidth
                  name="Date"
                >
                  <DateTimePicker
                    label="Date"
                    onChange={d => {
                      setDate(d.toDate())
                    }}
                    value={date}
                    variant="inline"
                  />
                </FormControl>
              </MuiPickersUtilsProvider>
              <Button
                className={classes.button}
                color="primary"
                fullWidth
                type="submit"
                variant="contained"
              >
                  Ajouter
              </Button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(NewGame);
