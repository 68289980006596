import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@material-ui/icons/ArrowDropUpOutlined';


const useStyles = makeStyles(theme => ({
  content: {
    padding: 0
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const RessourceTable = props => {
  const { filter, filtered, className, ressources, fields, count, backEndPagination, nextPage, changeTake, ressourcesTake, ressourcesPage, ...rest } = props;

  const classes = useStyles();

  const [selectedRessources, setSelectedRessources] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(ressources.length < 10 ? ressources.length : ressourcesTake);
  const [page, setPage] = useState(ressourcesPage);

  const handleSelectAll = event => {
    const { ressources } = props;

    let selectedRessources;

    if (event.target.checked) {
      selectedRessources = ressources.map(user => user.id);
    } else {
      selectedRessources = [];
    }

    setSelectedRessources(selectedRessources);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedRessources.indexOf(id);
    let newSelectedRessources = [];

    if (selectedIndex === -1) {
      newSelectedRessources = newSelectedRessources.concat(selectedRessources, id);
    } else if (selectedIndex === 0) {
      newSelectedRessources = newSelectedRessources.concat(selectedRessources.slice(1));
    } else if (selectedIndex === selectedRessources.length - 1) {
      newSelectedRessources = newSelectedRessources.concat(selectedRessources.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRessources = newSelectedRessources.concat(
        selectedRessources.slice(0, selectedIndex),
        selectedRessources.slice(selectedIndex + 1)
      );
    }

    setSelectedRessources(newSelectedRessources);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
    if (nextPage) nextPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
    if (changeTake) changeTake(event.target.value);
  };

  let data = ressources;

  if (!backEndPagination) {
    data = ressources.slice((0 + page * rowsPerPage), (rowsPerPage + page * rowsPerPage));
  }

  const getHeaderIcon = (type) => {
    if (filtered !== undefined && filtered.by === type) {
      if (filtered.type === 'asc') {
        return <ArrowDropUpOutlinedIcon />
      } else if (filtered.type === 'desc') {
        return <ArrowDropDownOutlinedIcon />
      }
    }
    return null;
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedRessources.length === ressources.length}
                      color="primary"
                      indeterminate={
                        selectedRessources.length > 0 &&
                        selectedRessources.length < ressources.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  {fields.map((f, i) => (
                    <TableCell
                      key={i}
                      onClick={() => filter !== undefined ? filter(f.name) : null}
                    >{getHeaderIcon(f.name)}{f.name}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map(ressource => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={ressource.id}
                    selected={selectedRessources.indexOf(ressource.id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedRessources.indexOf(ressource.id) !== -1}
                        color="primary"
                        onChange={event => handleSelectOne(event, ressource.id)}
                        value="true"
                      />
                    </TableCell>
                    {fields.map((f, i) => (
                      <TableCell key={i}>{f.display(ressource, classes)}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={count ? count : ressources.length}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} sur ${count}`}
          labelRowsPerPage="Éléments par page"
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

RessourceTable.propTypes = {
  backEndPagination: PropTypes.bool,
  changeTake: PropTypes.func,
  className: PropTypes.string,
  count: PropTypes.number,
  fields: PropTypes.array.isRequired,
  filter: PropTypes.func,
  filtered: PropTypes.object,
  nextPage: PropTypes.func,
  ressources: PropTypes.array.isRequired,
  ressourcesPage: PropTypes.number,
  ressourcesTake: PropTypes.number,
};

RessourceTable.defaultProps = {
  backEndPagination: false,
  ressourcesPage: 0,
  ressourcesTake: 10,
}

export default RessourceTable;
