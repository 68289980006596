import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Button } from '@material-ui/core';

import Loader from 'components/Loader';
import RessourceTable from 'components/RessourceTable';
import RessourceToolbar from 'components/RessourceToolBar';
import CustomRouterLink from 'components/CustomRouterLink';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { getPersonsByType } from 'services/persons';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const PlayerList = () => {
  const classes = useStyles();

  const [players, setPlayers] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchVal, setSearchVal] = useState('');
  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const players = await getPersonsByType('players');
        setPlayers(
          players.map(p => {
            return { ...p, id: p.playerId };
          })
        );
        setLoaded(true);
        setLoading(false);
      } catch (error) {
        setLoading(true);
        setLoading(false);
      }
    };
    if (!loaded) {
      fetchPlayers();
    }
  }, [loaded]);

  const fields = [
    {
      name: 'Prénom',
      display: p => p.firstName
    },
    {
      name: 'Nom',
      display: p => p.lastName
    },
    {
      name: 'Numéro',
      display: p => p.number
    },
    {
      name: 'Date de naissance',
      display: p =>
        moment(p.birthdate, 'DD-MM-YYYY HH:mm:ss').format('DD/MM/YYYY')
    },
    {
      name: 'Action',
      display: g => (
        <Button
          color="primary"
          component={CustomRouterLink}
          size="small"
          to={`/players/${g.id}`}
          variant="text"
        >
          Voir le joueur <ArrowRightIcon />
        </Button>
      )
    }
  ];

  return (
    <div className={classes.root}>
      <Typography variant="h1">Joueurs</Typography>
      <RessourceToolbar
        addTitle="Ajouter un joueur"
        searchTitle="Rechercher un joueur"
        searchVal={searchVal}
        setSearchVal={setSearchVal}
      />
      <div className={classes.content}>
        {loading ? (
          <Loader />
        ) : (
          <RessourceTable
            fields={fields}
            ressources={players}
          />
        )}
      </div>
    </div>
  );
};

export default PlayerList;
