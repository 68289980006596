import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

import { SearchInput } from 'components';
import { useDebounce } from 'helpers/utils';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const RessourceToolbar = props => {
  const { className, searchTitle, addTitle, onAdd, onImport, searchVal, setSearchVal, onSearch, ...rest } = props;
  const debouncedSearchTerm = useDebounce(searchVal, 500);
  const classes = useStyles();

  useEffect(() => {
    if (onSearch) {
      onSearch(searchVal);
    }
  }, [debouncedSearchTerm])

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        {onImport && <Button
          className={classes.importButton}
          onClick={onImport}
        >Importer</Button>}
        {/* <Button className={classes.exportButton}>Export</Button> */}
        <Button
          color="primary"
          onClick={onAdd}
          variant="contained"
        >
          {addTitle}
        </Button>
      </div>
      {onSearch && (
        <div className={classes.row}>
          <SearchInput
            className={classes.searchInput}
            onChange={(e) => setSearchVal(e.target.value)}
            placeholder={searchTitle}
            value={searchVal}
          />
        </div>
      )}
    </div>
  );
};

RessourceToolbar.propTypes = {
  addTitle: PropTypes.string,
  className: PropTypes.string,
  onAdd: PropTypes.func,
  onImport: PropTypes.func,
  onSearch: PropTypes.func,
  searchTitle: PropTypes.string,
  searchVal: PropTypes.string,
  setSearchVal: PropTypes.func
};

export default RessourceToolbar;
