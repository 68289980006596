import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const RouteWithLayout = props => {
  const { layout: Layout, component: Component, type, needAuth, ...rest } = props;
  if (needAuth) {
    const isAuth = localStorage.getItem('user');
    if (isAuth === null) {
      return <Redirect to="/sign-in" />
    }
  }
  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout>
          <Component
            {...matchProps}
            type={type}
          />
        </Layout>
      )}
    />
  );
};

RouteWithLayout.defaultProps = {
  needAuth: false
}

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  needAuth: PropTypes.bool.isRequired,
  path: PropTypes.string,
  type: PropTypes.string
};

export default RouteWithLayout;
