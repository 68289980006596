import React from 'react';
import {
  Card,
  CardHeader,
  Typography,
  CardContent,
  makeStyles,
  IconButton
} from '@material-ui/core';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import ChatIcon from '@material-ui/icons/Chat';
import TimerIcon from '@material-ui/icons/Timer';
import FlagIcon from '@material-ui/icons/Flag';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import PanToolIcon from '@material-ui/icons/PanTool';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import InfoIcon from '@material-ui/icons/Info';
import Media from 'components/Media';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import { getTeamFromPlayer, undeletableFacts, typeByName } from 'helpers/utils';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '600px',
    margin: '0 auto 20px'
  },
  yellow: {
    color: 'yellow'
  },
  red: {
    color: 'red'
  },
  cardHeader: {
    paddingBottom: '10px',
    borderBottom: '1px solid silver'
  },
  cardContent: {
    paddingTop: '10px'
  },
  header: {
    fontSize: '15px',
    fontWeight: 600,
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center'
  },
  swapIcon: {
    transform: 'scaleX(-1)',
    verticalAlign: 'middle'
  },
  factIcon: {
    marginRight: '5px',
    filter: 'drop-shadow( 1px 1px 1px rgba(0, 0, 0, .3))',
    textShadow: '0 0 10px silver'
  },
  factMin: {
    color: 'silver'
  },
  media: {
    width: '100%'
  },
  cardComment: {
    whiteSpace: 'pre-wrap',
    worldWrap: 'break-word'
  }
}));

const FactCard = props => {
  const classes = useStyles();
  const { fact, teams, deleteFact, updateFact } = props;
  let icon = '';
  let content = '';
  const media =
    fact.medias !== undefined ? (
      <Media
        className={classes.media}
        media={fact.medias}
      />
    ) : null;

  let team = null;
  if (fact.mainPlayer) {
    team = getTeamFromPlayer(fact.mainPlayer.playerId, teams);
    if (team === 'home') {
      team = teams.match.homeTeam;
    } else if (team === 'visitor') {
      team = teams.match.visitorTeam;
    }
  }
  switch (fact.fact.factType.name) {
    case 'OPPORTUNITY':
      icon = <InfoIcon />;
      break;
    case 'TENSION':
      icon = <FlashOnIcon />;
      break;
    case 'COMMENT':
    case 'SUMMARY':
      icon = <ChatIcon />;
      break;
    case 'REPLACEMENT':
      if (fact.mainPlayer && fact.secondPlayer) {
        content = (
          <>
            <Typography variant="body1">
              {fact.mainPlayer.number} {fact.mainPlayer.lastName}{' '}
              {fact.mainPlayer.firstName}
              <SwapVertIcon className={classes.swapIcon} />
              {fact.secondPlayer.number} {fact.secondPlayer.lastName}{' '}
              {fact.secondPlayer.firstName}
            </Typography>
          </>
        );
      }
      icon = <SwapVertIcon className={classes.swapIcon} />;
      break;
    case 'CORNER':
      icon = <FlagIcon />;
      if (fact.mainPlayer) {
        content = (
          <>
            <Typography variant="body1">
              {fact.mainPlayer.number} {fact.mainPlayer.lastName}{' '}
              {fact.mainPlayer.firstName}
            </Typography>
          </>
        );
      }
      break;
    case 'YELLOW_CARD':
      icon = <BookmarkIcon className={classes.yellow} />;
      if (fact.mainPlayer) {
        content = (
          <>
            <Typography variant="body1">
              {fact.mainPlayer.number} {fact.mainPlayer.lastName}{' '}
              {fact.mainPlayer.firstName}
            </Typography>
          </>
        );
      }
      break;
    case 'RED_CARD':
      icon = <BookmarkIcon className={classes.red} />;
      if (fact.mainPlayer) {
        content = (
          <>
            <Typography variant="body1">
              {fact.mainPlayer.number} {fact.mainPlayer.lastName}{' '}
              {fact.mainPlayer.firstName}
            </Typography>
          </>
        );
      }
      break;
    case 'PENALTY':
      icon = <FlashOnIcon />;
      if (fact.mainPlayer) {
        content = (
          <>
            <Typography variant="body1">
              {fact.mainPlayer.number} {fact.mainPlayer.lastName}{' '}
              {fact.mainPlayer.firstName}
            </Typography>
          </>
        );
      }
      break;
    case 'GOAL':
    case 'OWNGOAL':
      icon = <SportsSoccerIcon />;
      if (fact.mainPlayer) {
        content = (
          <>
            <Typography variant="body1">
              {fact.mainPlayer.number} {fact.mainPlayer.lastName}{' '}
              {fact.mainPlayer.firstName}
            </Typography>
          </>
        );
      }
      break;
    case 'FREEKICK':
      icon = <ControlCameraIcon />;
      if (fact.mainPlayer) {
        content = (
          <>
            <Typography variant="body1">
              {fact.mainPlayer.number} {fact.mainPlayer.lastName}{' '}
              {fact.mainPlayer.firstName}
            </Typography>
          </>
        );
      }
      break;
    case 'FOUL':
      icon = <PanToolIcon />;
      if (fact.mainPlayer && fact.secondPlayer) {
        content = (
          <>
            <Typography variant="body1">
              {fact.mainPlayer.number} {fact.mainPlayer.lastName}{' '}
              {fact.mainPlayer.firstName} sur {fact.secondPlayer.number}{' '}
              {fact.secondPlayer.lastName} {fact.secondPlayer.firstName}
            </Typography>
          </>
        );
      }
      break;
    case 'KICKOFF':
    case 'STARTSECONDHALF':
    case 'HALFTIME':
    case 'EXTRATIME':
    case 'HALFTIMEXTRA':
    case 'STARTSECONDXTRA':
    case 'END_EXTRATIME':
    case 'GAMEOVER':
      icon = <TimerIcon />;
      break;
    default:
      break;
  }

  return (
    <Card
      className={classes.root}
      key={fact.fact.id}
    >
      <CardHeader
        action={[
          (undeletableFacts.indexOf(fact.fact.factType.name) === -1 && <IconButton
            aria-label="edit"
            color="inherit"
            key="edit"
            onClick={() => updateFact(fact)}
          >
            <CreateIcon className={classes.icon} />
          </IconButton>),
          (undeletableFacts.indexOf(fact.fact.factType.name) === -1 &&
          <IconButton
            aria-label="delete"
            color="inherit"
            key="delete"
            onClick={() => deleteFact(fact.fact.id)}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>)
        ]}
        className={classes.cardHeader}
        title={
          <Typography className={classes.header}>
            <span className={classes.header}>
              <span className={classes.factIcon}>{icon}</span>
              <span className={classes.factName}>
                {typeByName[fact.fact.factType.name]}
                {team !== null &&  team !== undefined && ' ' + team.name}
              </span>
            </span>
            <span className={classes.factMin}>
              {fact.fact.minute !== undefined && '(' + fact.fact.minute + '\')'}
            </span>
          </Typography>
        }
      />
      <CardContent className={classes.cardContent}>
        {media}
        {content}
        <pre className={classes.cardComment}><Typography variant="body1">{fact.fact.note}</Typography></pre>
      </CardContent>
    </Card>
  );
};

FactCard.propTypes = {
  fact: PropTypes.any.isRequired,
  teams: PropTypes.any
};

export default FactCard;
