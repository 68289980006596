import React from 'react';
import { Switch, Redirect, withRouter, Route } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Dashboard as DashboardView,
  UserList as UserListView,
  GameList as GameListView,
  DivisionsList as DivisionsListView,
  ChampionnatsList as ChampionnatsListView,
  PlayerList as PlayerListView,
  TeamList as TeamListView,
  Team as TeamView,
  Game as GameView,
  NewGame as NewGameView,
  NewChampionnat as NewChampionnatView,
  Account as AccountView,
  Settings as SettingsView,
  // SignUp as SignUpView,
  SignIn as SignInView,
  Logout as LogoutView,
  Download as DownloadView,
  NotFound as NotFoundView
} from './views';

const Routes = props => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/games"
      />
      {/* <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        needAuth
        path="/dashboard"
      /> */}
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={DownloadView}
        exact
        layout={MinimalLayout}
        path="/download"
      />
      <RouteWithLayout
        component={GameListView}
        exact
        layout={MainLayout}
        needAuth
        path="/games"
        type="games"
      />
      <RouteWithLayout
        component={ChampionnatsListView}
        exact
        layout={MainLayout}
        needAuth
        path="/championnats"
        type="games"
      />
      <RouteWithLayout
        component={DivisionsListView}
        exact
        layout={MainLayout}
        needAuth
        path="/divisions"
        type="games"
      />
      <RouteWithLayout
        component={NewGameView}
        exact
        layout={MainLayout}
        needAuth
        path="/games/new"
        type="games"
      />
      <RouteWithLayout
        component={GameView}
        exact
        layout={MainLayout}
        needAuth
        path="/games/:id"
        type="games"
      />
      <RouteWithLayout
        component={TeamListView}
        exact
        layout={MainLayout}
        needAuth
        path="/teams"
        type="teams"
      />
      <RouteWithLayout
        component={TeamView}
        exact
        layout={MainLayout}
        needAuth
        path="/teams/:id"
        type="teams"
      />
      <RouteWithLayout
        component={PlayerListView}
        exact
        layout={MainLayout}
        needAuth
        path="/players"
        type="players"
      />
      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        needAuth
        path="/collectors"
        type="collectors"
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        needAuth
        path="/account"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        needAuth
        path="/settings"
      />
      <Route
        component={LogoutView}
        path="/logout"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default withRouter(Routes);
