import { authRequest } from 'helpers/request';
import endpoints from 'helpers/api';

export const getTeams = async (page, take) => {
  try {
    const data = await authRequest(endpoints.getTeams(page, take));
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getTeam = async (id) => {
  try {
    const data = await authRequest(endpoints.getTeam(id));
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getTeamPlayers = async (id) => {
  try {
    const data = await authRequest(endpoints.getTeamPlayers(id));
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getTeamMatches = async (id) => {
  try {
    const data = await authRequest(endpoints.getTeamMatches(id));
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const filterTeam = async (name, division, page = 0, take = 10) => {
  try {
    const query = `?${name !== '' ? `name=${name}&`: ''}take=${take}&page=${page}${division !== '' ? `&division=${division}&`: ''}`
    const data = await authRequest(endpoints.getTeamsByName(query));
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const importTeam = async (body, teamId) => {
  const formData = new FormData();

  formData.append('csv', body);
  try {
    const data = await authRequest(endpoints.importTeamPlayers(teamId), {
      method: 'POST',
      body: formData,
    });
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
}
