import jwt_decode from 'jwt-decode';
import * as ls from '../helpers/localStorage';
import { request, authRequest } from '../helpers/request';
import endpoints from '../helpers/api';

export const login = async (mail, password) => {
  try {
    const resp = await request(endpoints.login, {
      method: 'POST',
      body: JSON.stringify({ mail, password }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    });
    const claims = jwt_decode(resp.accesToken);
    await ls.set('user', {
      ...resp,
      ...claims
    });
  } catch (error) {
    throw new Error(error.message);
  }
};

export const logout = async () => {
  try {
    // delete all data from ls
    await ls.del('user');
  } catch (error) {
    throw new Error(error.message);
  }
};

export const addUser = async (user) => {
  try {
    const data = await authRequest(endpoints.addUser, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      },
      body: JSON.stringify(user),
    });
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const editUser = async (userId, user) => {
  try {
    const data = await authRequest(endpoints.getUser(userId), {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      },
      body: JSON.stringify(user),
    });
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getUsers = async () => {
  try {
    const data = await authRequest(endpoints.getUsers);
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const deleteUser = async (userId) => {
  try {
    const data = await authRequest(endpoints.getUser(userId), {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    });
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getUserRoles = async () => {
  try {
    const data = await authRequest(endpoints.getUserRoles);
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};
