import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import {
  Dialog, DialogTitle, DialogContent,
  TextField, DialogActions, Button,
  FormControl, InputLabel, Select, MenuItem, FormHelperText
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { typeByName, factsWithPlayers } from 'helpers/utils';
import Loader from 'components/Loader';

const FactDialog = ({ teams, fact, factTypes, isOpen, cancel, update, isLoading }) => {
  const players = [...teams.home.holders, ...teams.visitor.holders]
  const [type, setType] = useState(fact ? fact.fact.factType.id : 1);
  const [firstMatchPlayerId, setFirstMatchPlayerId] = useState(fact ? fact.mainPlayer ? players.find(p => p.playerId === fact.mainPlayer.playerId).id : '' : '');
  const [secondMatchPlayerId, setSecondMatchPlayerId] = useState(fact ? fact.secondPlayer ? players.find(p => p.playerId === fact.secondPlayer.playerId).id : '' : '');
  const [typeError, setTypeError] = useState('');
  const { register, errors, handleSubmit, setValue, watch } = useForm();

  const { minute, note } = watch();

  useEffect(() => {
    register({ name: 'minute' });
    register({ name: 'note' });
    if(fact!=null){
      setValue('note', fact.fact.note);
      setValue('minute', fact.fact.minute);
    }
  }, [register]);

  const handleChange = (name, e) => {
    e.persist();
    setValue(name, e.target.value);
  };

  const handleUpdate = async (data) => {
    if(fact != null){
      update({
        ...data,
        id: fact.fact.id,
        factType: type,
        firstMatchPlayerId,
        secondMatchPlayerId,
      })
    }else{
      update({
        ...data,
        factType: type,
        firstMatchPlayerId,
        secondMatchPlayerId,
      })
    }
  };

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      onClose={cancel}
      open={isOpen}
    >
      {isLoading ? (
        <div style={{ minWidth: '400px' }}>
          <Loader />
        </div>
      ) : (
        <form onSubmit={handleSubmit(handleUpdate)}>
          <DialogTitle id="form-dialog-title">Modifier le fait</DialogTitle>
          <DialogContent>
            <FormControl
              error={typeError !== '' ? true : false}
              fullWidth
              name="type"
            >
              <InputLabel
                id="fact-type-label"
              >Type de fait</InputLabel>
              <Select
                fullWidth
                id="fact-type"
                onChange={(e) => setType(e.target.value)}
                value={type}
              >
                {factTypes.map(r => (<MenuItem
                  key={r.id}
                  selected={r.id === type}
                  value={r.id}
                >
                  {typeByName[r.name]}
                </MenuItem>))}
              </Select>
              {typeError !== '' && <FormHelperText>{typeError}</FormHelperText>}
            </FormControl>
            {factsWithPlayers.indexOf(factTypes.find(f => f.id === type).name) !== -1 && (
              <>
                <FormControl
                  error={typeError !== '' ? true : false}
                  fullWidth
                  name="firstMatchPlayerId"
                >
                  <InputLabel
                    id="player-one-label"
                  >Joueur 1</InputLabel>
                  <Select
                    fullWidth
                    id="player-one"
                    onChange={(e) => setFirstMatchPlayerId(e.target.value)}
                    value={firstMatchPlayerId}
                  >
                    {players.map(p => (<MenuItem
                      key={p.id}
                      selected={p.id === firstMatchPlayerId}
                      value={fact != null ? p.id : p.playerId}
                    >
                      {p.firstName + ' ' + p.lastName}
                    </MenuItem>))}
                  </Select>
                  {typeError !== '' && <FormHelperText>{typeError}</FormHelperText>}
                </FormControl>

                <FormControl
                  error={typeError !== '' ? true : false}
                  fullWidth
                  name="secondMatchPlayerId"
                >
                  <InputLabel
                    id="player-two-label"
                  >Joueur 2</InputLabel>
                  <Select
                    fullWidth
                    id="player-two"
                    onChange={(e) => setSecondMatchPlayerId(e.target.value)}
                    value={secondMatchPlayerId}
                  >
                    {players.map(p => (<MenuItem
                      key={p.id}
                      selected={p.id === secondMatchPlayerId}
                      value={fact != null ? p.id : p.playerId}
                    >
                      {p.firstName + ' ' + p.lastName}
                    </MenuItem>))}
                  </Select>
                  {typeError !== '' && <FormHelperText>{typeError}</FormHelperText>}
                </FormControl>

              </>
            )}
            <TextField
              autoFocus
              error={errors.minute ? true : false}
              fullWidth
              helperText={
                errors.minute && errors.minute.message
              }
              inputRef={register({
                required: 'Ce champ est requis'
              })}
              label="Minute"
              margin="dense"
              name="minute"
              onChange={e => handleChange('minute', e)}
              type="text"
              value={minute}
            />
            <TextField
              error={errors.note ? true : false}
              fullWidth
              helperText={
                errors.note && errors.note.message
              }
              // inputRef={register({
              //   required: 'Ce champ est requis'
              // })}
              label="Note"
              margin="dense"
              name="note"
              onChange={e => handleChange('note', e)}
              type="text"
              value={note}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={cancel}
            >
                Annuler
            </Button>
            <Button
              color="primary"
              type="submit"
              variant="contained"
            >
              {fact != null ? 'Mettre à jour' : 'Ajouter'}
            </Button>
          </DialogActions>
        </form>

      )}
    </Dialog>
  );
}

FactDialog.propTypes = {
  cancel: PropTypes.func.isRequired,
  fact: PropTypes.any,
  factTypes: PropTypes.any.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  teams: PropTypes.any,
  update: PropTypes.func.isRequired
};

export default FactDialog;