import * as ls from '../helpers/localStorage';
import endpoints from '../helpers/api';
import { authRequest } from '../helpers/request';

export const getGames = async (params) => {
  try {
    const data = await authRequest(endpoints.getGames(params));
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getChampionships = async () => {
  try {
    const data = await authRequest(endpoints.getChamps);
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const addChampionships = async body => {
  try {
    const data = await authRequest(endpoints.addChamps, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    });
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const editChampionships = async (Id, body) => {
  try {
    const data = await authRequest(endpoints.addChamps, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    });
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const deleteChampionships = async (champId) => {
  try {
    const data = await authRequest(endpoints.addChamps +'/'+ champId, {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    });
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getDivisionsByChampionship = async (champId) => {
  try {
    const data = await authRequest(endpoints.getDivisions(champId));
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const addDivision = async (champId, body) => {
  try {
    const data = await authRequest(endpoints.getDivisions(champId), {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    });
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const editDivision = async (champId, body) => {
  try {
    const data = await authRequest(endpoints.getDivisions(champId), {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    });
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const deleteDivision = async (champId, divisionId) => {
  try {
    const data = await authRequest(endpoints.getDivisions(champId) +'/'+ divisionId, {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    });
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getGame = async id => {
  try {
    const data = await authRequest(endpoints.getGame(id));
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
}

export const getGameTeams = async id => {
  try {
    const data = await authRequest(endpoints.getGameTeams(id));
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getGameSheet = async id => {
  try {
    const data = await authRequest(endpoints.getGameSheet(id));
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getGameFacts = async id => {
  try {
    const data = await authRequest(endpoints.getGameFacts(id));
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getGameSummary = async id => {
  try {
    const data = await authRequest(endpoints.getGameSummary(id));
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const addGame = async (body) => {
  try {
    const data = await authRequest(endpoints.addGame, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    });
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const editGame = async (body) => {
  try {
    const data = await authRequest(endpoints.addGame, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    });
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const deleteGameSheet = async gameId => {
  try {
    const data = await authRequest(endpoints.deleteGameSheet(gameId),
      {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      });
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const deleteGame = async gameId => {
  try {
    const data = await authRequest(endpoints.deleteGame(gameId),
      {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      });
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const setGame = async game => {
  try {
    const body = {
      teams: [
        {
          teamId: game.homeTeam.id,
          holders: game.homeMain,
          substitutes: game.homeSub
        },
        {
          teamId: game.visitorTeam.id,
          holders: game.visitorMain,
          substitutes: game.visitorSub
        }
      ]
    };
    await authRequest(endpoints.addGameSheet(game.id),
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      });
    await ls.set('game', game);
    return game;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const putGame = async game => {
  try {
    await ls.set('game', game);
    return game;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const exportGames = async () => {
  try {
    const data = await authRequest(endpoints.addGame +'/Export', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    });
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};