import endpoints from '../helpers/api';
import { authRequest } from '../helpers/request';
import { addVideo } from './files';

export const getFactTypes = async () => {
  try {
    const data = await authRequest(endpoints.getFactTypes, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    });
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};


export const addFact = async (fact, video = undefined) => {
  try {
    const data = await authRequest(endpoints.addFact, {
      method: 'POST',
      body: JSON.stringify(fact),
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    });
    console.log(data, video);
    if (video !== undefined) {
      // TODO: add this in background
      await addVideo(data, video);
    }
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const deleteFact = async factID => {
  try {
    const data = await authRequest(endpoints.deleteFact(factID),
      {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      });
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const updateFact = async fact => {
  try {
    const data = await authRequest(endpoints.addFact,
      {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(fact),
      });
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};
