import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'moment/locale/fr';

import Loader from 'components/Loader';
import {
  getGames,
  getChampionships,
  getDivisionsByChampionship,
  editGame, 
  exportGames
} from 'services/games';
import RessourceTable from 'components/RessourceTable';
import RessourceToolbar from 'components/RessourceToolBar';
import CustomRouterLink from 'components/CustomRouterLink';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import TeamNameWithAvatar from 'components/TeamNameWithAvatar';
import { urlParam } from 'helpers/utils';

moment.locale('fr');

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  formControl: {
    minWidth: 120
  },
  filtersTitle: {
    marginTop: '20px'
  },
  filters: {
    paddingTop: '30px',
    paddingBottom: '30px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
  }
}));

const now = new Date();
const nextWeek = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000);

const GameList = (props) => {
  const { history } = props;
  const classes = useStyles();

  const [games, setGames] = useState([]);
  const [champ, setChamp] = useState('FOOTBALL PROVINCIAL PREMIERES');
  const [champs, setChamps] = useState([]);
  const [from, setFrom] = useState(now);
  const [to, setTo] = useState(nextWeek);
  const [division, setDivision] = useState('Namur 1');
  const [divisionsLoading, setDivisionsLoading] = useState(false);
  const [divisions, setDivisions] = useState([]);
  const [filteredGames, setFilteredGames] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [showFiltered, setShowFiltered] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchGames = async (p = null) => {
    const params = {
      champ,
      division,
      from: `${from.getFullYear()}-${from.getMonth() + 1}-${from.getDate()}`,
      to: `${to.getFullYear()}-${to.getMonth() + 1}-${to.getDate()}`
    };
    try {
      setLoading(true);
      const games = await getGames(p !== null ? p : params);
      setGames(games);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchChamps = async () => {
    try {
      const champs = await getChampionships();
      setChamps(champs);
      return champs;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDivisions = async champId => {
    setDivisionsLoading(true);
    try {
      const dvs = await getDivisionsByChampionship(champId);
      setDivisions(dvs);
      setDivisionsLoading(false);
      return dvs;
    } catch (error) {
      setDivisionsLoading(false);
    }
  };

  const init = async () => {
    const queryChamp = urlParam('champ');
    const queryDivision = urlParam('division');
    const queryFrom = urlParam('from');
    const queryTo = urlParam('to');
    try {
      const ch = await fetchChamps();
      let selectedChampId = ch[0].id;
      if (queryChamp !== null) {
        setChamp(queryChamp);
        selectedChampId = ch.find(c => c.name === queryChamp).id;
      } else {
        selectedChampId = ch.find(c => c.name === champ).id;
      }
      await fetchDivisions(selectedChampId);
      if (queryDivision !== null) {
        setDivision(queryDivision);
      }
      const params = {
        champ: queryChamp !== null ? queryChamp : champ,
        division: queryDivision !== null ? queryDivision : division,
        from:
          queryFrom !== null
            ? queryFrom
            : `${from.getFullYear()}-${from.getMonth() + 1}-${from.getDate()}`,
        to:
          queryTo !== null
            ? queryTo
            : `${to.getFullYear()}-${to.getMonth() + 1}-${to.getDate()}`
      };
      await fetchGames(params);
      setLoaded(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setLoaded(true);
    }
  };

  const handleChange = async (event, g) => {
    //await send to API
    g.isCovered = event.target.checked;
    var game = await editGame(g);
    // if ok change
    setGames(
      games.map(el => (el.id === g.id ? Object.assign({}, el, { isCovered: game.isCovered}) : el))
    );
  };

  useEffect(() => {
    if (!loaded) {
      init();
    }
  }, [loaded]);

  const fields = [
    {
      name: 'Home',
      display: (g, c) => <TeamNameWithAvatar
        classes={c}
        team={g.homeTeam}
      />
    },
    {
      name: 'Visiteurs',
      display: (g, c) => <TeamNameWithAvatar
        classes={c}
        team={g.visitorTeam}
      />
    },
    {
      name: 'Date du match',
      display: g => `${moment(g.date, 'DD-MM-YYYY HH:mm').format('DD/MM/YYYY')} ${moment(g.startHour,'HH:mm').format('HH:mm')}`
    },
    {
      name: 'Match Couvert',
      display: g => <Checkbox
        checked={g.isCovered}
        onChange={event => handleChange(event, g)}
        color="primary"
      />
    },
    {
      name: 'Action',
      display: g => (
        <Button
          color="primary"
          component={CustomRouterLink}
          size="small"
          to={`/games/${g.id}`}
          variant="text"
        >
          Voir le match <ArrowRightIcon />
        </Button>
      )
    }
  ];

  const handleChampChange = async e => {
    setChamp(e.target.value);
    const selectedChamp = champs.find(c => c.name === e.target.value);
    await fetchDivisions(selectedChamp.id);
  };

  const handleFilter = () => {
    const fromDate = `${from.getFullYear()}-${from.getMonth() +
      1}-${from.getDate()}`;
    const toDate = `${to.getFullYear()}-${to.getMonth() + 1}-${to.getDate()}`;
    if (window.history.pushState) {
      const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?champ=${champ}&division=${division}&from=${fromDate}&to=${toDate}`;
      window.history.pushState({ path: newurl }, '', newurl);
    }
    setFilteredGames([]);
    setShowFiltered(false);
    fetchGames();
  };

  const handleExport = async () => {
    try {
      setLoading(true);
      await exportGames();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <div className={classes.root}>
      <Typography variant="h1">Matches</Typography>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div>
            <Typography
              className={classes.filtersTitle}
              variant="h3"
            >Filtres</Typography>
            <div className={classes.filters}>
              <MuiPickersUtilsProvider
                libInstance={moment}
                locale="fr"
                utils={MomentUtils}
              >
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="age-simple">Championnat</InputLabel>
                  <Select
                    onChange={handleChampChange}
                    value={champ}
                  >
                    {champs.map(c => (
                      <MenuItem
                        key={c.id}
                        value={c.name}
                      >
                        {c.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {divisionsLoading ? (
                  <Typography variant="body1">
                    Chargement des divisions...
                  </Typography>
                ) : (
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="age-simple">Division</InputLabel>
                    <Select
                      onChange={e => setDivision(e.target.value)}
                      value={division}
                    >
                      {divisions.map(d => (
                        <MenuItem
                          key={d.id}
                          value={d.name}
                        >
                          {d.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                <DatePicker
                  format="DD/MM/yyyy"
                  label="À partir du"
                  onChange={d => setFrom(d.toDate())}
                  value={from}
                  variant="inline"
                />
                <DatePicker
                  format="DD/MM/yyyy"
                  label="Jusqu'au"
                  onChange={d => setTo(d.toDate())}
                  value={to}
                  variant="inline"
                />
                <Button
                  color="primary"
                  onClick={handleFilter}
                >
                  Filtrer
                </Button>
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <RessourceToolbar
            addTitle="Ajouter un match"
            onAdd={() => history.push('/games/new')}
            searchTitle="Rechercher un match"
          />
          <RessourceToolbar
            addTitle="Exporter les matchs suivis"
            onAdd={() => {
              handleExport();
            }}
            searchTitle="Exporter les matchs suivis"
          />
          <div className={classes.content}>
            <RessourceTable
              fields={fields}
              ressources={showFiltered ? filteredGames : games}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default GameList;
