import React, { useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent,
  DialogActions, Button,
  FormControl
} from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import {  DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'moment/locale/fr';
import Loader from 'components/Loader';

const FactDialog = ({ game, isOpen, cancel, update, isLoading }) => {
  const [date, setDate] = useState(new Date());

  const handleUpdate = async () => {
    update({
      id: game.id,
      date,
      startHour: moment(date).format('HH:mm'),
    });
  };

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      onClose={cancel}
      open={isOpen}
    >
      {isLoading ? (
        <div style={{ minWidth: '400px' }}>
          <Loader />
        </div>
      ) : (
        <form onSubmit={handleUpdate}>
          <DialogTitle id="form-dialog-title">Reporter le match</DialogTitle>
          <DialogContent>
            <MuiPickersUtilsProvider
              libInstance={moment}
              locale="fr"
              utils={MomentUtils}
            >
              <FormControl
                fullWidth
                name="Date"
              >
                <DateTimePicker
                  label="Date"
                  onChange={d => setDate(d.toDate())}
                  value={date}
                  variant="inline"
                />
              </FormControl>
            </MuiPickersUtilsProvider>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={cancel}
            >
                Annuler
            </Button>
            <Button
              color="primary"
              type="submit"
              variant="contained"
            >
                Mettre à jour
            </Button>
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
}

FactDialog.propTypes = {
  cancel: PropTypes.func.isRequired,
  game: PropTypes.any.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired
};

export default FactDialog;