import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Typography, Button,
  DialogActions, TextField,
  DialogContent, DialogTitle, Dialog,
  FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';
import useForm from 'react-hook-form';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'moment/locale/fr';

import Loader from 'components/Loader';
import {
  getChampionships,
  getDivisionsByChampionship
} from 'services/games';
import Message from 'components/Message/Message';
import RessourceTable from 'components/RessourceTable';
import RessourceToolbar from 'components/RessourceToolBar';
import { urlParam } from 'helpers/utils';
import { addDivision, editDivision, deleteDivision } from 'services/games';

moment.locale('fr');

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  formControl: {
    minWidth: 120
  },
  filtersTitle: {
    marginTop: '20px'
  },
  filters: {
    paddingTop: '30px',
    paddingBottom: '30px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
  }
}));

const DivisionsList = (props) => {
  const { history } = props;
  const classes = useStyles();

  const [champ, setChamp] = useState({id:'0b4139af-903b-4bff-1f37-08d756cad443', name:'FOOTBALL PROVINCIAL PREMIERES'});
  const [champs, setChamps] = useState([]);
  const [filteredGames, setFilteredGames] = useState([]);
  const [showFiltered, setShowFiltered] = useState(false);
  const [divisions, setDivisions] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [divisionsLoading, setDivisionsLoading] = useState(false);
  const [divisionDialogOpen, setDivisionDialogOpen] = useState(false);
  const [divisionError, setDivisionError] = useState('');
  const [loadingMsg, setLoadingMsg] = useState('Chargement');
  const [divisionSaveType, setDivisionSaveType] = useState('add');
  const [divisionId, setDivisionId] = useState(null);
  const [divisionValue, setDivisionValue] = useState('');
  const { register, handleSubmit, errors, watch, setValue, reset } = useForm({
    defaultValues: {
      name: divisionValue,
    }
  });

  const fetchChamps = async () => {
    try {
      const champs = await getChampionships();
      setChamps(champs);
      return champs;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDivisions = async champId => {
    setDivisionsLoading(true);
    try {
      const dvs = await getDivisionsByChampionship(champId);
      setDivisions(dvs);
      setDivisionsLoading(false);
      return dvs;
    } catch (error) {
      setDivisionsLoading(false);
    }
  };

  const init = async () => {
    const queryChamp = urlParam('champ');
    try {
      const ch = await fetchChamps();
      let selectedChampId = ch[0].id;
      let selectedChamp;
      if (queryChamp !== null) {
        selectedChamp = ch.find(c => c.name === queryChamp);
        selectedChampId = selectedChamp.id;
        setChamp(selectedChamp);
      } else {
        selectedChamp = ch.find(c => c.name === champ.name)
        selectedChampId = selectedChamp.id;
      }
      await fetchDivisions(selectedChampId);
      setLoading(false);
      setLoaded(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setLoaded(true);
    }
  };

  const deleteDivById = async (champId, divId) => {
    setLoading(true);
    if (champId) {
      try {
        await deleteDivision(champId, divId);
        await fetchDivisions(champ.id);
        setLoading(false);
      } catch (error) {
        //setFormError(error.message);
        setLoading(false);
      }
    }
  }


  useEffect(() => {
    if (!loaded) {
      init();
    }
  }, [loaded]);

  const fields = [
    {
      name: 'Nom',
      display: g => `${g.name}`
    },
    {
      name: 'Action',
      display: p => (
        <>
          <Button
            color="primary"
            onClick={()=> {
              reset();
              setDivisionId(p.id);
              setValue('name', p.name);
              setDivisionValue(p.name);
              setDivisionSaveType('edit');
              setDivisionDialogOpen(true);
            }}
            size="small"
            variant="text"
          >
            Modifier
          </Button>
          <Button
            color="primary"
            onClick={()=> {
              const ok = window.confirm(`Êtes-vous certain de vouloir supprimer cette division: ${p.name}?`);
              if (ok) {
                deleteDivById(champ.id, p.id);
              }
            }}
            size="small"
            variant="contained"
          >
            Supprimer
          </Button>
        </>
      )
    }
  ];


  const openDivisionDialog = () => setDivisionDialogOpen(!divisionDialogOpen);

  const handleDivision = async data => {
    setLoadingMsg('Envoi des données');
    try {
      setLoading(true);
      switch (divisionSaveType) {
        case 'add':
          await addDivision(champ.id, { ...data});
          break;
        case 'edit':
          if (divisionId) {
            await editDivision(champ.id, { id: divisionId, ...data});
          }
          break;
        default:
          setDivisionError('Action inconnue');
          setLoading(false);
          setDivisionDialogOpen(true);
          return;
      }
      await fetchDivisions(champ.id);
      setDivisionValue('');
      setDivisionDialogOpen(false);
      setLoading(false);
    } catch (error) {
      setDivisionError(error.message);
      setLoading(false);
      setDivisionDialogOpen(true);
    }
  }

  const handleChange = (name, e) => {
    e.persist();
    setDivisionValue(e.target.value);
    setValue(name, e.target.value);
  };

  const handleChampChange = async e => {
    const selectedChamp = champs.find(c => c.name === e.target.value);
    setChamp(selectedChamp);
    await fetchDivisions(selectedChamp.id);
  };

  const handleFilter = () => {
    if (window.history.pushState) {
      const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?division=${champ}`;
      window.history.pushState({ path: newurl }, '', newurl);
    }
    setFilteredGames([]);
    setShowFiltered(false);
    fetchDivisions(champ);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h1">Divisions</Typography>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div>
            <Typography
              className={classes.filtersTitle}
              variant="h3"
            >Filtres</Typography>
            <div className={classes.filters}>
              <MuiPickersUtilsProvider
                libInstance={moment}
                locale="fr"
                utils={MomentUtils}
              >
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="age-simple">Championnat</InputLabel>
                  <Select
                    onChange={handleChampChange}
                    value={champ.name}
                  >
                    {champs.map(c => (
                      <MenuItem
                        key={c.id}
                        value={c.name}
                      >
                        {c.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  color="primary"
                  onClick={handleFilter}
                >
                  Filtrer
                </Button>
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <RessourceToolbar
            addTitle="Ajouter une Division"
            onAdd={() => {
              setDivisionSaveType('add');
              setDivisionValue('');
              openDivisionDialog();
            }}
            searchTitle="Rechercher un joueur"
          />
          <div className={classes.content}>
            <RessourceTable
              fields={fields}
              ressources={divisions}
            />
          </div>
        </>
      )}
      <Dialog
        aria-labelledby="form-dialog-title"
        onClose={openDivisionDialog}
        open={divisionDialogOpen}
      >
        <form onSubmit={handleSubmit(handleDivision)}>
          <DialogTitle id="form-dialog-title">Ajouter une Division</DialogTitle>
          <DialogContent>
            {divisionError !== '' && <Message
              intent="DANGER"
              message={divisionError}
            />}
            <Typography
              className={classes.title}

              style={{ color: '#d60b13' }}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              Championnat:
            </Typography>
            <Typography variant="body1">{champ.name}</Typography>
            <TextField
              autoFocus
              error={errors.name ? true : false}
              fullWidth
              helperText={
                errors.name  && errors.name.message
              }
              id="name"
              inputRef={register({
                required: 'Prénom requis'
              })}
              label="Nom"
              margin="dense"
              name="name"
              onChange={e => handleChange('name', e)}
              type="text"
              value={divisionValue}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={openDivisionDialog}
            >
                      Annuler
            </Button>
            <Button
              color="primary"
              type="submit"
              variant="contained"
            >
              SAVE
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default DivisionsList;
