import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Button } from '@material-ui/core';

import Loader from 'components/Loader';
import RessourceTable from 'components/RessourceTable';
import RessourceToolbar from 'components/RessourceToolBar';
import CustomRouterLink from 'components/CustomRouterLink';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { filterTeam } from 'services/teams';
import TeamNameWithAvatar from 'components/TeamNameWithAvatar';
import { urlParam } from 'helpers/utils';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const TeamList = () => {
  const classes = useStyles();
  const querySearch = urlParam('search');
  const queryPage = urlParam('page');
  const queryTake = urlParam('take');

  const [teams, setTeams] = useState([]);
  const [take, setTake] = useState(queryTake !== null ? Number(queryTake) : 10);
  const [ressourcesPage, setRessourcesPage] = useState(queryPage !== null ? Number(queryPage) : 0);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [searchVal, setSearchVal] = useState(querySearch !== null ? querySearch : '');
  const fetchTeams = async (page, toTake, search) => {
    try {
      const data = await filterTeam(search ? search : '', '', page + 1, toTake);
      setCount(data.item1);
      setTeams(data.item2);
      setLoaded(true);
      setLoading(false);
    } catch (error) {
      setLoaded(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!loaded) {
      fetchTeams(ressourcesPage, take, searchVal);
    }
  });

  const fields = [
    {
      name: 'Nom',
      display: (t, c) => <TeamNameWithAvatar
        classes={c}
        team={t}
      />
    },
    {
      name: 'Division',
      display: (t, c) => <p>{t.division}</p>
    },
    {
      name: 'Action',
      display: g => (
        <Button
          color="primary"
          component={CustomRouterLink}
          size="small"
          to={`/teams/${g.id}`}
          variant="text"
        >
          Voir l'équipe <ArrowRightIcon />
        </Button>
      )
    }
  ];

  const nextPage = (page) => {
    if (window.history.pushState) {
      const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?page=${page}&take=${take}${searchVal.length ? `&search=${searchVal}` : ''}`;
      window.history.pushState({ path: newurl }, '', newurl);
    }
    setLoading(true);
    setRessourcesPage(page);
    fetchTeams(page, take, searchVal);
  }

  const changeTake = (newTake) => {
    setTake(newTake)
    if (window.history.pushState) {
      const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?page=${ressourcesPage}&take=${newTake}${searchVal.length ? `&search=${searchVal}` : ''}`;
      window.history.pushState({ path: newurl }, '', newurl);
    }
    setLoading(true);
    fetchTeams(ressourcesPage, newTake, searchVal);
  }

  const handleSearch = async search => {
    if (search.length) {
      setLoading(true);
      setTake(10);
      setRessourcesPage(0);
      if (window.history.pushState) {
        const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?page=${0}&take=${10}&search=${search}`;
        window.history.pushState({ path: newurl }, '', newurl);
      }
      await fetchTeams(0, 10, search);
    } else {
      if (window.history.pushState) {
        const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?page=${0}&take=${10}`;
        window.history.pushState({ path: newurl }, '', newurl);
      }
      setLoading(true);
      setTake(10);
      setRessourcesPage(0);
      fetchTeams(0, 10);
    }
  };

  return (
    <div className={classes.root}>
      <Typography variant="h1">Équipes</Typography>
      <RessourceToolbar
        addTitle="Ajouter une équipe"
        onSearch={handleSearch}
        searchTitle="Rechercher une équipe"
        searchVal={searchVal}
        setSearchVal={setSearchVal}
      />
      {loading ? (
        <Loader />
      ) : (
        <div className={classes.content}>
          <RessourceTable
            backEndPagination
            changeTake={changeTake}
            count={count}
            fields={fields}
            nextPage={nextPage}
            ressources={teams}
            ressourcesPage={ressourcesPage}
            ressourcesTake={take}
          />
        </div>
      )}
    </div>
  );
};

export default TeamList;
