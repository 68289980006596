import endpoints from '../helpers/api';
import { authRequest } from '../helpers/request';


export const getPersonsByType = async (type) => {
  try {
    const data = await authRequest(endpoints.getPersons(type));
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const addPerson = async (person, type) => {
  try {
    const data = await authRequest(endpoints.addPerson(type), {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      },
      body: JSON.stringify(person),
    });
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};


export const updatePerson = async (person, type) => {
  try {
    const data = await authRequest(endpoints.addPerson(type), {
      method: 'PUT',
      body: JSON.stringify(person),
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    });
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};



export const deletePerson = async (personId, type) => {
  try {
    const data = await authRequest(endpoints.getPerson(type, personId), {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    });
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};