export const set = async (itemName, data) => {
  try {
    await localStorage.setItem(itemName, JSON.stringify(data));
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const get = async itemName => {
  try {
    const value = await localStorage.getItem(itemName);
    return JSON.parse(value);
  } catch (error) {
    throw new Error(error.message);
  }
};

export const del = async itemName => {
  try {
    await localStorage.removeItem(itemName);
    return true;
  } catch (error) {
    throw new Error(error.message);
  }
};
