import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(2)
  },
  container: {
    display: 'flex',
    minHeight: '400px',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export default function Loader({ message = 'chargement' }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div>
        <CircularProgress className={classes.progress} />
        <Typography variant="body1">{message}</Typography>
      </div>
    </div>
  );
}
