const API_BASE_URI = process.env.REACT_APP_API_URL;

const endpoints = {
  login: `${API_BASE_URI}/User/Authenticate`,
  refreshToken: `${API_BASE_URI}/User/refresh`,
  getUsers: `${API_BASE_URI}/User/list`,
  addUser: `${API_BASE_URI}/User/register`,
  getUser: userId => `${API_BASE_URI}/User/${userId}`,
  getUserRoles: `${API_BASE_URI}/User/roles`,
  getFactTypes: `${API_BASE_URI}/Fact/types`,
  addFact: `${API_BASE_URI}/Fact`,
  deleteFact: factId => `${API_BASE_URI}/Fact/${factId}`,
  getChamps: `${API_BASE_URI}/Championships`,
  addChamps: `${API_BASE_URI}/Championships`,
  getDivisions: champId => `${API_BASE_URI}/Championships/${champId}/divisions`,
  addVideoFact: factId => `${API_BASE_URI}/Fact/${factId}/Video`,
  getGames: params => `${API_BASE_URI}/Matches/BackOffice/?championship=${params.champ}&take=2000&division=${params.division}&from=${params.from}&to=${params.to}`,
  getGame: gameId => `${API_BASE_URI}/Matches/${gameId}`,
  getGameTeams: gameId => `${API_BASE_URI}/Matches/${gameId}/Teams`,
  getGameFacts: gameId => `${API_BASE_URI}/Fact/Details/${gameId}`,
  getGameSheet: gameId => `${API_BASE_URI}/Matches/${gameId}/GameSheet`,
  getGameSummary: gameId => `${API_BASE_URI}/Matches/${gameId}/Summary`,
  addGameSheet: gameId => `${API_BASE_URI}/Matches/${gameId}/GameSheet`,
  addGame: `${API_BASE_URI}/Matches`,
  deleteGameSheet: gameId => `${API_BASE_URI}/Matches/${gameId}/GameSheet`,
  deleteGame: gameId => `${API_BASE_URI}/Matches/${gameId}`,
  getTeams: (page, take) => `${API_BASE_URI}/Team?page=${page}&take=${take}`,
  getTeam: teamId => `${API_BASE_URI}/Team/${teamId}`,
  getTeamPlayers: teamId => `${API_BASE_URI}/Team/${teamId}/players`,
  getTeamMatches: teamId => `${API_BASE_URI}/Team/${teamId}/matches`,
  getTeamsByName: (query) => `${API_BASE_URI}/Team/filter${query}`,
  importTeamPlayers: teamId => `${API_BASE_URI}/People/players/import/${teamId}`,
  getPersons: type => `${API_BASE_URI}/People/${type}`,
  getPerson: (type, personId) => `${API_BASE_URI}/People/${type}/${personId}`,
  addPerson: type => `${API_BASE_URI}/People/${type}`,
};

export default endpoints;
