import jwt_decode from 'jwt-decode';
import * as ls from './localStorage';
import endpoints from './api';

export const request = async (url, options) => {
  const res = await fetch(url, options);
  if (res.ok) {
    const data = await res.json();
    return data;
  }
  const contentType = res.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    const data = await res.json();
    throw new Error(data.Errors.Name[0]);
  }
  throw new Error(res.statusText);
};

export const authRequest = async (url, options) => {
  const user = await ls.get('user');
  if (user === null) {
    await ls.del('user');
    window.location.reload();
  }
  const reqOptions = options || {};
  reqOptions.headers = reqOptions.headers || {};
  reqOptions.headers.Authorization = `Bearer ${user.accesToken}`;
  const response = await fetch(url, reqOptions);
  if (response.ok) {
    return response.json();
  }
  if (response.status === 401) {
    if (
      response.headers.has('token-expired') &&
      response.headers.get('token-expired') === 'true'
    ) {
      await refreshToken(user);
      return authRequest(url, reqOptions);
    }
    await ls.del('user');
    window.location.reload();
  } else {
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      const data = await response.json();
      throw new Error(data.Errors.Name[0]);
    }
    throw new Error(response.statusText);
  }
};

export const refreshToken = async user => {
  try {
    const resp = await request(endpoints.refreshToken, {
      method: 'POST',
      body: JSON.stringify({
        accesToken: user.accesToken,
        refreshToken: user.refreshToken
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    });
    const claims = jwt_decode(resp.accesToken);
    await ls.set('user', {
      ...resp,
      ...claims
    });
  } catch (error) {
    await ls.del('user');
    // window.location.reload();
  }
};
