import { withRouter, Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Loader from 'components/Loader';
import { Typography, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { getGameSheet, getGameFacts, getGame, deleteGameSheet, deleteGame, editGame } from 'services/games';
import { deleteFact, updateFact, getFactTypes, addFact } from 'services/facts';
import { GameContent, FactDialog, ReportDialog } from './components';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarWrapper from 'components/SnackbarWrapper';
import { undeletableFacts } from 'helpers/utils';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  title: {
    display: 'flex',
    justifyContent: 'center'
  },
  againstIcon: {
    fontSize: '35px',
    marginLeft: '15px',
    marginRight: '15px'
  }
}));

const Game = props => {
  const gameId = props.match.params.id;
  const { history } = props;
  const classes = useStyles();

  const [game, setGame] = useState(undefined);
  const [teams, setTeams] = useState(undefined);
  const [facts, setFacts] = useState([]);
  const [factTypes, setFactTypes] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackVariant, setSnackVariant] = useState('success');
  const [snackMessage, setSnackMessage] = useState('');
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(true);
  const [teamsError, setTeamsError] = useState('');
  const [factsError, setFactsError] = useState('');
  const [gameError, setGameError] = useState('');
  const [editOpen, setEditOpen] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [editFact, setEditFact] = useState(undefined);
  const [newFact, setNewFact] = useState(undefined);

  const refreshFacts = async () => {
    try {
      const resfacts = await getGameFacts(gameId);
      var sortedFacts = resfacts.sort(function (a, b) {
        if(b.fact.factType.name == 'KICKOFF'){
          return -1;
        }
        if (a.fact.minute <= b.fact.minute) {
          return 1;
        }
        if (a.fact.minute >= b.fact.minute) {
          return -1;
        }
        return 0;
      });
      setFacts(sortedFacts);
      // setFacts(resfacts);
    } catch (error) {
      console.log(error)
    }
  }

  const reportGame = async (data) => {
    if (game.date === data.date) {
      setSnackVariant('error');
      setSnackMessage('La date n\'a pas changé');
      setSnackOpen(true);
      return;
    }
    setReportLoading(true);
    try {
      setFetching(true);
      await editGame(data);
      const game = await getGame(gameId);
      setGame(game);
      setShowReportModal(false);
      setSnackVariant('success');
      setSnackMessage('Match mit à jour!');
    } catch (error) {
      setFetching(false);
      setSnackVariant('error');
      setSnackMessage(error.message);
    }
    setReportLoading(false);
    setSnackOpen(true);
  }

  const removeGame = async () => {
    try {
      setFetching(true);
      await deleteGame(gameId);
      history.goBack();
    } catch (error) {
      setFetching(false);
      setSnackVariant('error');
      setSnackMessage(error.message);
    }
    setSnackOpen(true);
  }

  const deleteGamesheet = async () => {
    try {
      setFetching(true);
      await deleteGameSheet(gameId);
      setFetching(false);
      setLoaded(false);
      setSnackVariant('success');
      setSnackMessage('GameSheet supprimée!');
    } catch (error) {
      setFetching(false);
      setSnackVariant('error');
      setSnackMessage(error.message);
    }
    setSnackOpen(true);
  }

  const deleteGameFact = async (factId) => {
    try {
      await deleteFact(factId);
      const facts = await getGameFacts(gameId);
      setFacts(facts);
      setSnackVariant('success');
      setSnackMessage('Fait supprimé!');
    } catch (error) {
      setSnackVariant('error');
      setSnackMessage(error.message);
    }
    setSnackOpen(true);
  }
  const openAddFact = () => {
    setNewFact(true);
    setEditOpen(true);
  }

  const openEditFact = (fact) => {
    setEditFact(fact);
    setEditOpen(true);
  }

  const addGameFact = async (fact) => {
    try {
      console.log('addGameFact', fact);
      setEditLoading(true);
      const uFact = await addFact({ ...fact, matchId: gameId });
      console.log('uFact', uFact);
      refreshFacts();
      setEditLoading(false);
      cancelUpdate();
      setSnackVariant('success');
      setSnackMessage('Fait Ajouté!');
    } catch (error) {
      setEditLoading(false);
      setSnackVariant('error');
      setSnackMessage(error.message);
    }
    setSnackOpen(true);
  }


  const updateGameFact = async (fact) => {
    try {
      setEditLoading(true);
      const uFact = await updateFact({ ...fact, matchId: gameId });
      setFacts(facts.map(f => f.fact.id === uFact.fact.id ? uFact : f));
      setEditLoading(false);
      cancelUpdate();
      setSnackVariant('success');
      setSnackMessage('Fait mit à jour!');
    } catch (error) {
      setEditLoading(false);
      setSnackVariant('error');
      setSnackMessage(error.message);
    }
    setSnackOpen(true);
  }

  const handleCloseSnack = () => {
    setSnackOpen(false);
  }

  const cancelUpdate = () => {
    setEditOpen(false);
    setEditFact(undefined);
  }

  useEffect(() => {
    let interval;
    let respTeam;
    const fetchGame = async () => {
      setFetching(true);
      try {
        const ft = await getFactTypes();
        setFactTypes(ft.filter(f => undeletableFacts.indexOf(f.name) === -1));
      } catch (error) {
        console.log(error)
      }
      try {
        const game = await getGame(gameId);
        setGame(game);
        setGameError('');
      } catch (error) {
        setGameError(error.message);
        return;
      }
      try {
        respTeam = await getGameSheet(gameId);
        setTeams(respTeam);
        setTeamsError('');
      } catch (error) {
        setTeamsError(error.message);
      }
      try {
        const facts = await getGameFacts(gameId);
        var sortedFacts = facts.sort(function (a, b) {
          if(b.fact.factType.name == 'KICKOFF'){
            return -1;
          }
          if (a.fact.minute <= b.fact.minute) {
            return 1;
          }
          if (a.fact.minute >= b.fact.minute) {
            return -1;
          }
          return 0;
        });
        setFacts(sortedFacts);
        // if (facts.find(f => f.fact.factType.name === 'GAMEOVER') === undefined && respTeam !== undefined) {
        //   interval = setInterval(() => refreshFacts(), 5000);
        // }
        setFactsError('');
      } catch (error) {
        setFactsError(error.message);
      }
      setLoaded(true);
      setLoading(false);
    };
    if (!loaded && !fetching) {
      fetchGame();
    }
    return function cleanup() {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [loaded, fetching, gameId]);

  if (gameError !== '') {
    return (<div className={classes.root}>
      <Typography variant="h1">{gameError}</Typography>
    </div>)
  }

  const gameTitle =
    game !== undefined ? (
      <div className={classes.title}>
        <Link
          className={classes.title}
          to={`/teams/${game.homeTeam.id}`}
        >
          <Avatar
            className={classes.avatar}
            src={game.homeTeam.logo}
          />
          <Typography variant="h1">{game.homeTeam.name}</Typography>
        </Link>
        <CompareArrowsIcon className={classes.againstIcon} />
        <Link
          className={classes.title}
          to={`/teams/${game.visitorTeam.id}`}
        >
          <Avatar
            className={classes.avatar}
            src={game.visitorTeam.logo}
          />
          <Typography variant="h1">{game.visitorTeam.name}</Typography>
        </Link>
      </div>
    ) : (
      ''
    );
  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        open={snackOpen}
      >
        <SnackbarWrapper
          message={snackMessage}
          onClose={handleCloseSnack}
          variant={snackVariant}
        />
      </Snackbar>
      {gameTitle}
      {/* <UsersToolbar /> */}
      <div className={classes.content}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <GameContent
              addFact={openAddFact}
              deleteGame={removeGame}
              deleteGameFact={deleteGameFact}
              deleteGameSheet={deleteGamesheet}
              facts={facts}
              factsError={factsError}
              game={game}
              reportGame={() => setShowReportModal(true)}
              teams={teams}
              teamsError={teamsError}
              updateGameFact={openEditFact}
            />
            {showReportModal && (
              <ReportDialog
                cancel={() => setShowReportModal(false)}
                game={game}
                isLoading={reportLoading}
                isOpen={showReportModal}
                update={reportGame}
              />
            )}
            {newFact && (
              <FactDialog
                cancel={cancelUpdate}
                fact={null}
                factTypes={factTypes}
                isLoading={editLoading}
                isOpen={editOpen}
                teams={teams}
                update={addGameFact}
              />
            )}
            {editFact && (
              <FactDialog
                cancel={cancelUpdate}
                fact={editFact}
                factTypes={factTypes}
                isLoading={editLoading}
                isOpen={editOpen}
                teams={teams}
                update={updateGameFact}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default withRouter(Game);
