/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Typography, Button,
  DialogActions, TextField,
  DialogContent, DialogTitle, Dialog
} from '@material-ui/core';
import useForm from 'react-hook-form';
import moment from 'moment';

import 'moment/locale/fr';

import Loader from 'components/Loader';
import {
  getChampionships,
} from 'services/games';
import Message from 'components/Message/Message';
import RessourceTable from 'components/RessourceTable';
import RessourceToolbar from 'components/RessourceToolBar';

import { addChampionships, editChampionships, deleteChampionships } from 'services/games';
import { rest } from 'underscore';

moment.locale('fr');

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  formControl: {
    minWidth: 120
  },
  filtersTitle: {
    marginTop: '20px'
  },
  filters: {
    paddingTop: '30px',
    paddingBottom: '30px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
  }
}));

const ChampionnatsList = (props) => {
  const { history } = props;
  const classes = useStyles();


  const [champs, setChamps] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [champDialogOpen, setChampDialogOpen] = useState(false);
  const [champError, setChampError] = useState('');
  const [loadingMsg, setLoadingMsg] = useState('Chargement');
  const [championnatSaveType, setChampionnatSaveType] = useState('add');
  const [champId, setChampId] = useState(null);
  const [champValue, setChampValue] = useState('');
  const { register, handleSubmit, errors, watch, setValue, reset } = useForm({
    defaultValues: {
      name: champValue,
    }
  });

  const fetchChamps = async () => {
    try {
      const champs = await getChampionships();
      setChamps(champs);
      return champs;
    } catch (error) {
      console.log(error);
    }
  };

  const init = async () => {
    try {
      await fetchChamps();
      setLoading(false);
      setLoaded(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setLoaded(true);
    }
  };

  useEffect(() => {
    if (!loaded) {
      init();
    }
  }, [loaded]);

  // const { name } = watch();

  useEffect(() => {
    register({ name: 'name' });
  }, [register]);

  const fields = [
    {
      name: 'Nom',
      display: g => `${g.name}`
    },
    {
      name: 'Action',
      display: p => (
        <>
          <Button
            color="primary"
            onClick={()=> {
              reset();
              setChampId(p.id);
              setValue('name', p.name);
              setChampValue(p.name);
              setChampionnatSaveType('edit');
              setChampDialogOpen(true);
            }}
            size="small"
            variant="text"
          >
            Modifier
          </Button>
          <Button
            color="primary"
            onClick={()=> {
              const ok = window.confirm(`Etes-vous certain de vouloir supprimer cette compétition: ${p.name}?`);
              if (ok) {
                deleteChampById(p.id);
              }
            }}
            size="small"
            variant="contained"
          >
            Supprimer
          </Button>
        </>
      )
    }
  ];

  const openChampDialog = () => setChampDialogOpen(!champDialogOpen);

  const handleChamp = async data => {
    setLoadingMsg('Envoi des données');
    try {
      console.log('try sending the championship', data);
      setLoading(true);
      switch (championnatSaveType) {
        case 'add':
          await addChampionships({ ...data});
          break;
        case 'edit':
          if (champId) {
            await editChampionships(champId, { id: champId, ...data});
          }
          break;
        default:
          setChampError('Action inconnue');
          setLoading(false);
          setChampDialogOpen(true);
          return;
      }
      const champData = await getChampionships();
      setChamps(
        champData
      );
      setChampValue('');
      setChampDialogOpen(false);
      setLoading(false);
    } catch (error) {
      setChampError(error.message);
      setLoading(false);
      setChampDialogOpen(true);
    }
  }

  const handleChange = (name, e) => {
    e.persist();
    setChampValue(e.target.value);
    setValue(name, e.target.value);
  };

  const deleteChampById = async (champId) => {
    setLoading(true);
    if (champId) {
      try {
        await deleteChampionships(champId);
        const champData = await getChampionships();
        setChamps(champData);
        setLoading(false);
      } catch (error) {
        //setFormError(error.message);
        setLoading(false);
      }
    }
  }

  return (
    <div className={classes.root}>
      <Typography variant="h1">Championnats</Typography>
      {loading ? (
        <Loader message={loadingMsg} />
      ) : (
        <>
          <RessourceToolbar
            addTitle="Ajouter un Championnat"
            onAdd={() => {
              setChampionnatSaveType('add');
              setChampValue('');
              openChampDialog();
            }}
            searchTitle="Rechercher un joueur"
          />
          <div className={classes.content}>
            <RessourceTable
              fields={fields}
              ressources={champs}
            />
          </div>
        </>
      )}
      <Dialog
        aria-labelledby="form-dialog-title"
        onClose={openChampDialog}
        open={champDialogOpen}
      >
        <form onSubmit={handleSubmit(handleChamp)}>
          <DialogTitle id="form-dialog-title">Ajouter un Championnat</DialogTitle>
          <DialogContent>
            {champError !== '' && <Message
              intent="DANGER"
              message={champError}
            />}
            <TextField
              autoFocus
              error={errors.name ? true : false}
              fullWidth
              helperText={
                errors.name  && errors.name.message
              }
              id="name"
              inputRef={register({
                required: 'Prénom requis'
              })}
              label="Nom"
              margin="dense"
              name="name"
              onChange={e => handleChange('name', e)}
              type="text"
              value={champValue}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={openChampDialog}
            >
                      Annuler
            </Button>
            <Button
              color="primary"
              type="submit"
              variant="contained"
            >
              SAVE
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default ChampionnatsList;
